import className from 'classnames';
import {
  memo,
  useMemo,
  useState,
  useEffect,
  useLayoutEffect,
} from 'react';
import {
  Droppable,
  Draggable,
  DropResult,
  DragDropContext,
} from 'react-beautiful-dnd';
import { useSelector, batch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Divider, LoadingOverlay } from '@mantine/core';
import { useTheme } from 'src/shared/libs/hooks/use-theme';
import { EThemeMode } from 'src/app/store/slices/user/types';
import { BalanceType } from 'src/entities/balance-type';
import { useAppDispatch } from 'src/app/store/store';
import { ColumnOrderItem } from 'src/pages/trading/model/types';
import { HIDDEN_BALANCES } from 'src/shared/constants/constants';
import { EStatus, Nullable } from 'src/shared/types/global-types';
import { selectHiddenBalance } from 'src/pages/settings/model/selectors';
import { CONVERSION_ASSETS, dustTransformsAssetsColumn } from 'src/pages/account/constants';
import { addDustTransformHistory, clearDustTransforms, removeDustTransformsAssetByIds } from 'src/pages/account/model/slice';
import {
  DustTransformsConversionParams, fetchDustTransformsAssets, fetchDustTransformsConversion, fetchDustTransformsCountdown,
} from 'src/pages/account/model/thunks';
import {
  toFixedDecimalPrecision,
  getFromLocalStorage, saveToLocalStorage, secondsToTime, subtractOneSecond,
} from 'src/shared/libs/helpers/helper.lib';
import { ReactComponent as SortIconSvg } from 'src/shared/assets/images/sort.svg';

import Hint from 'src/shared/ui/help/hint';
import Empty from 'src/entities/empty/empty';
import Select from 'src/shared/ui/select/select';
import Button from 'src/shared/ui/button/button/button';
import useAlert from 'src/shared/libs/hooks/use-alert';
import GlassIcon from 'src/shared/assets/icons/glass-icon/glass-icon';
import InputText from 'src/shared/ui/input/input-text/input-text';
import InputForm from 'src/shared/ui/input/input-form/input-form';
import { SortFieldDustTransforms, DustTransforms, DustTransformsHistory } from '../../../../model/types';
import { selectAccount } from '../../../../model/selectors';
import styles from './dust-transforms-assets-table.module.scss';

interface IDustTransformsAssetsTableProps {
    subAccountId: Nullable<number>
}

const DustTransformsAssetsTable = memo(({ subAccountId }: IDustTransformsAssetsTableProps) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const { theme } = useTheme();
  const { setAlertMessage } = useAlert();

  const {
    dustTransformsAssets,
    dustTransformsCountdown,
    statusDustTransformsAssets,
    statusDustTransformsConversion,
  } = useSelector(selectAccount);

  const balanceVisible = useSelector(selectHiddenBalance);

  const [searchInput, setSearchInput] = useState('');
  const [startTimer, setStartTimer] = useState(false);
  const [countdownTime, setCountdownTime] = useState('00:00:00');
  const [currentSortColumn, setCurrentSortColumn] = useState('');
  const [sortOrder, setSortOrder] = useState<'asc' | 'desc'>('asc');
  const [selectedItems, setSelectedItems] = useState<string[]>([]);
  const [sortField, setSortField] = useState<Nullable<SortFieldDustTransforms>>(null);
  const [selectedDust, setSelectedDust] = useState<DustTransforms[]>([]);
  const [conversionAsset, setConversionAsset] = useState<'BNB' | 'USDT'>(CONVERSION_ASSETS[0]);
  const [columnOrder, setColumnOrder] = useState<ColumnOrderItem[]>(dustTransformsAssetsColumn);
  const [sortedDustTransformsAssets, setSortedDustTransformsAssets] = useState<DustTransforms[] | []>(dustTransformsAssets || []);

  const totalQuantity = useMemo(() => selectedDust.reduce((acc: number, current: DustTransforms) => {
    let price = 0;
    if (conversionAsset === 'USDT') {
      price = parseFloat(current.priceInUSDT);
    } else if (conversionAsset === 'BNB') {
      price = parseFloat(current.priceInBNB);
    }
    return acc + price;
  }, 0), [selectedDust, conversionAsset]);

  const trimNumberOfCharacters = useMemo(() => {
    let quantity = 0;
    if (conversionAsset === 'USDT') {
      quantity = 2;
    } else if (conversionAsset === 'BNB') {
      quantity = 8;
    } else {
      quantity = 8;
    }
    return quantity;
  }, [conversionAsset]);

  const onDragEnd = (result: DropResult) => {
    if (!result.destination) return;

    const newOrder = [...columnOrder];
    const [movedColumn] = newOrder.splice(result.source.index, 1);
    newOrder.splice(result.destination.index, 0, movedColumn);

    setColumnOrder(newOrder);

    saveToLocalStorage('dustTransformsAssetsColumn', newOrder);
  };

  const handleSort = (field: SortFieldDustTransforms) => {
    setCurrentSortColumn(field);

    if (field === sortField) {
      setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
    } else {
      setSortField(field);
      setSortOrder('asc');
    }
  };

  const handleSelectItem = (dust: DustTransforms) => {
    if (selectedItems.find((element) => element === dust.id)) {
      setSelectedItems((prev) => {
        prev = prev.filter((id) => id !== dust.id);
        return prev;
      });
    } else {
      setSelectedItems((prev) => {
        prev = [...prev, dust.id];
        return prev;
      });
    }

    if (selectedDust.find((element) => element.id === dust.id)) {
      setSelectedDust((prev) => {
        prev = prev.filter((element) => element.id !== dust.id);
        return prev;
      });
    } else {
      setSelectedDust((prev) => {
        prev = [...prev, dust];
        return prev;
      });
    }
  };

  const handleSelectAll = () => {
    const assetIds = sortedDustTransformsAssets.map((asset) => asset.id);
    if (selectedItems.length < 1) {
      setSelectedItems([...assetIds]);
    } else {
      setSelectedItems([]);
    }

    if (selectedDust.length < 1) {
      setSelectedDust(sortedDustTransformsAssets);
    } else {
      setSelectedDust([]);
    }
  };

  const dustConversion = async () => {
    if (!subAccountId) return;

    const assetIds = selectedDust.map((dust) => dust.asset_id);

    const params: DustTransformsConversionParams = {
      sub_account_id: subAccountId,
      asset_ids: assetIds,
      transform_to_asset: conversionAsset,
    };

    const response = await dispatch(fetchDustTransformsConversion(params));
    const data = response.payload as DustTransformsHistory;

    if (data) {
      batch(() => {
        dispatch(removeDustTransformsAssetByIds(assetIds));
        dispatch(addDustTransformHistory(data));
        dispatch(fetchDustTransformsCountdown(subAccountId));
      });
      setAlertMessage('success', 'success');

      setSelectedDust([]);
      setSelectedItems([]);
    } else if (typeof data === 'boolean' && data === false) {
      setAlertMessage('conversion_failed', 'error');
    } else if (typeof data === 'string') {
      setAlertMessage(data, 'error');
    } else {
      console.debug(response);
      setAlertMessage('internal error', 'error');
    }
  };

  const conversionButtonStatus = () => {
    if (conversionAsset === 'USDT' && totalQuantity < 10) return true;
    if (statusDustTransformsConversion === EStatus.loading || selectedItems.length === 0) return true;
    if (startTimer) return true;

    return false;
  };

  useEffect(() => {
    if (dustTransformsCountdown) {
      const countdown = secondsToTime(dustTransformsCountdown);

      setStartTimer(true);
      setCountdownTime(countdown);
    }
  }, [dustTransformsCountdown]);

  useEffect(() => {
    let timerId: ReturnType<typeof setTimeout>;

    if (startTimer) {
      timerId = setInterval(() => {
        const updateTime = subtractOneSecond(countdownTime);

        if (countdownTime === '00:00:00') {
          setStartTimer(false);
          clearInterval(timerId);
        } else {
          setCountdownTime(updateTime);
        }
      }, 1000);
    }

    return () => {
      clearInterval(timerId);
    };
  }, [startTimer, countdownTime]);

  useEffect(() => {
    if (dustTransformsAssets) {
      setSortedDustTransformsAssets(dustTransformsAssets);
    } else {
      setSortedDustTransformsAssets([]);
    }
  }, [dustTransformsAssets]);

  useEffect(() => {
    const sorted: DustTransforms[] = dustTransformsAssets ? [...dustTransformsAssets].sort((a, b) => {
      if (sortField === 'asset') {
        const symbolA = a.asset.symbol;
        const symbolB = b.asset.symbol;

        if (symbolA < symbolB) {
          return sortOrder === 'asc' ? -1 : 1;
        }
        if (symbolA > symbolB) {
          return sortOrder === 'asc' ? 1 : -1;
        }
        return 0;
      }

      if (sortField === 'quantity') {
        const quantityA = parseFloat(a.quantity);
        const quantityB = parseFloat(b.quantity);

        if (quantityA < quantityB) {
          return sortOrder === 'asc' ? -1 : 1;
        }
        if (quantityA > quantityB) {
          return sortOrder === 'asc' ? 1 : -1;
        }
        return 0;
      }

      if (sortField === 'priceInUSDT') {
        const priceA = parseFloat(a.priceInUSDT);
        const priceB = parseFloat(b.priceInUSDT);

        if (priceA < priceB) {
          return sortOrder === 'asc' ? -1 : 1;
        }
        if (priceA > priceB) {
          return sortOrder === 'asc' ? 1 : -1;
        }
        return 0;
      }

      if (sortField === 'priceInBNB') {
        const priceA = parseFloat(a.priceInBNB);
        const priceB = parseFloat(b.priceInBNB);

        if (priceA < priceB) {
          return sortOrder === 'asc' ? -1 : 1;
        }
        if (priceA > priceB) {
          return sortOrder === 'asc' ? 1 : -1;
        }
        return 0;
      }

      if (sortField) {
        const aValue = a[sortField];
        const bValue = b[sortField];

        if (aValue !== undefined && bValue !== undefined && aValue !== null && bValue !== null) {
          if (aValue < bValue) {
            return sortOrder === 'asc' ? -1 : 1;
          }
          if (aValue > bValue) {
            return sortOrder === 'asc' ? 1 : -1;
          }
        }
      }
      return 0;
    }) : [];

    setSortedDustTransformsAssets(sorted);
  }, [sortField, sortOrder]);

  useEffect(() => {
    if (!subAccountId) return;

    batch(() => {
      dispatch(fetchDustTransformsAssets(subAccountId));
      dispatch(fetchDustTransformsCountdown(subAccountId));
    });
  }, [subAccountId]);

  useLayoutEffect(() => {
    const dustTransformsAssetsColumnLocalStorage = getFromLocalStorage('dustTransformsAssetsColumn', null);

    if (dustTransformsAssetsColumnLocalStorage) {
      setColumnOrder(dustTransformsAssetsColumnLocalStorage);
    }

    return () => {
      dispatch(clearDustTransforms());
    };
  }, []);

  return (
    <section className={styles.dustTransformsAssets}>
      <header className={styles.header}>
        <h2 className={styles.title}>{t('available_assets')}</h2>

        <InputText
          className={styles.searchInput}
          value={searchInput}
          placeholder={t('search')}
          onChange={setSearchInput}
        ><GlassIcon />
        </InputText>
      </header>

      <div className={styles.historyTableWrapper}>
        <DragDropContext onDragEnd={onDragEnd}>
          <table className={styles.tablePositions}>
            <thead>
              <Droppable droppableId="columns" direction="horizontal">
                {(provided, snapshot) => (
                  <tr
                    ref={provided.innerRef}
                    {...provided.droppableProps}
                    style={{
                      background: snapshot.isDraggingOver ? (theme === 'light' ? '#131313' : '#474747') : (theme === 'light' ? '#131313' : '#474747'),
                    }}
                  >
                    {columnOrder.map((column, index) => (
                      <Draggable key={column.key} draggableId={column.key} index={index} disableInteractiveElementBlocking>
                        {(provided, snapshot) => (
                          <th
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            style={{
                              ...provided.draggableProps.style,
                              background: snapshot.isDragging ? 'transparent' : (theme === 'light' ? '#131313' : '#474747'),
                              color: snapshot.isDragging ? 'var(--color-main)' : '#BCBCBC',
                              display: snapshot.isDragging ? 'flex' : '',
                              width: column.key === 'checkbox' ? '50px' : 'auto',
                            }}
                          >
                            {column.key === 'checkbox' && (
                              <input
                                className={styles.checkbox}
                                type="checkbox"
                                checked={selectedItems.length === sortedDustTransformsAssets.length && sortedDustTransformsAssets.length > 0}
                                onChange={handleSelectAll}
                              />
                            )}
                            {column.key !== 'checkbox' && (
                              <div
                                role="button"
                                tabIndex={0}
                                onKeyDown={() => handleSort(column.key as SortFieldDustTransforms)}
                                onClick={() => handleSort(column.key as SortFieldDustTransforms)}
                                className={className(styles.thWrapper, { [styles.activeColumn]: currentSortColumn === column.key })}
                              >
                                {t(column.label)}
                                <SortIconSvg />
                              </div>
                            )}

                          </th>
                        )}
                      </Draggable>
                    ))}
                    { provided.placeholder}
                  </tr>
                )}
              </Droppable>
            </thead>
            {statusDustTransformsAssets === EStatus.success && sortedDustTransformsAssets.length > 0 && (
              <tbody>
                {sortedDustTransformsAssets.filter(({ asset }) => asset.symbol.toLowerCase().includes(searchInput.toLowerCase())).map((dust) => (
                  <tr key={dust.id} className="history-item">
                    {columnOrder.map((column) => (
                      <td key={column.key}>
                        {column.key === 'checkbox' && (
                          <div>
                            <input
                              className={styles.checkbox}
                              type="checkbox"
                              checked={Boolean(selectedItems.find((id) => id === dust.id))}
                              onChange={() => handleSelectItem(dust)}
                            />
                          </div>
                        )}
                        {column.key === 'asset' && (
                          <div
                            className={className(styles.tdWrapper, styles.cursor)}
                            role="button"
                            tabIndex={0}
                            onClick={() => handleSelectItem(dust)}
                            onKeyDown={() => handleSelectItem(dust)}
                          >
                            <img src={dust.asset.image} alt="asset" />
                            <span className={styles.symbol}>{dust.asset.symbol}</span>
                            <span className={styles.name}>{dust.asset.name}</span>
                            <BalanceType type={dust.asset_type} />
                          </div>
                        )}
                        {column.key === 'quantity' && (
                          <div className={className(styles.tdWrapper, 'mask')}>
                            {!balanceVisible ? toFixedDecimalPrecision(dust.quantity) : HIDDEN_BALANCES}
                          </div>
                        )}
                        {column.key === 'priceInUSDT' && (
                          <div className={className(styles.tdWrapper, 'mask')}>
                            {!balanceVisible ? toFixedDecimalPrecision(dust.priceInUSDT) : HIDDEN_BALANCES}
                          </div>
                        )}
                        {column.key === 'priceInBNB' && (
                          <div className={className(styles.tdWrapper, 'mask')}>
                            {!balanceVisible ? toFixedDecimalPrecision(dust.priceInBNB) : HIDDEN_BALANCES}
                          </div>
                        )}
                      </td>
                    ))}
                  </tr>
                ))}
              </tbody>
            )}
          </table>
        </DragDropContext>
        {statusDustTransformsAssets === EStatus.loading && sortedDustTransformsAssets.length === 0 && (
          <div className={styles.loadingWrapper}>
            <LoadingOverlay
              className="loader"
              visible
              zIndex={1000}
              overlayProps={{ radius: 'sm', blur: 2 }}
              loaderProps={{ color: '#00C37C', type: 'dots' }}
            />
          </div>
        )}
        {statusDustTransformsAssets === EStatus.success && sortedDustTransformsAssets.length === 0 && (
          <div className={styles.emptyWrapper}>
            <Empty>{t('no_available_assets')}</Empty>
          </div>
        )}
        {statusDustTransformsAssets === EStatus.rejected && (
          <Empty className={styles.emptyTextError} horizontal error>
            {t('internal error fetch')}
          </Empty>
        )}
      </div>

      <Divider color={theme === EThemeMode.LIGHT ? '#D9D9D9' : '#3A3A3A'} style={{ margin: '20px 0' }} />

      <div className={styles.convertWrapper}>
        <div className={styles.convertBody}>
          <p className={styles.bodyTitle}>{t('you_will_receive')}</p>
          <p className={styles.bodySum}>{totalQuantity ? !balanceVisible ? `≈ ${toFixedDecimalPrecision(String(totalQuantity), trimNumberOfCharacters)}` : HIDDEN_BALANCES : 0} {conversionAsset}</p>

          <Hint
            hintOpen
            text=""
            offset={3}
            position="bottom-start"
            hintText={(
              <div>
                {t('conversion_rules')}
                <br /> <br />
                {t('convertible_assets')}
                <br />
                {t('convertible_conditions_1')}
                <br />
                {t('convertible_conditions_2')}
                <br />
                {t('convertible_conditions_3')}
                <br />  <br />
                {t('non_convertible_assets')}
                <br />
                {t('convertible_conditions_4')}
                <br />
                {t('convertible_conditions_5')}
                <br />
                {t('convertible_conditions_6')}
                <br />
                {t('convertible_conditions_7')}
                <br />
                {t('convertible_conditions_8')}
                <br />
                {t('convertible_conditions_9')}
              </div>
            )}
          />
        </div>
        <div className={styles.convertAction}>
          <InputForm minWidth="150px" maxWidth="150px" title={t('convert_to')}>
            <Select isActiveSearch={false}>
              { CONVERSION_ASSETS.map((asset) => (
                <span
                  key={asset}
                  className={styles.selectItem}
                  role="button"
                  tabIndex={0}
                  onKeyDown={() => setConversionAsset(asset)}
                  onClick={() => setConversionAsset(asset)}
                >
                  {asset}
                </span>
              )) }
            </Select>
          </InputForm>
          <Button
            onClick={dustConversion}
            background={conversionButtonStatus() ? 'black' : 'green'}
            loading={statusDustTransformsConversion === EStatus.loading}
            disabled={conversionButtonStatus()}
            maxWidth="250px"
          >
            <span className={styles.btnInfo}>{startTimer ? countdownTime : t('transfer')}</span>
          </Button>
        </div>
      </div>
    </section>
  );
});

export default DustTransformsAssetsTable;
