import {
  BaseSyntheticEvent, Ref, forwardRef, useEffect, useState,
} from 'react';
import classNames from 'classnames';

import { ReactComponent as EyeOpenedSvg } from 'src/shared/assets/images/auth/eye-opened.svg';
import { ReactComponent as EyeClosedSvg } from 'src/shared/assets/images/auth/eye-closed.svg';

import usePasswordExecutionSteps from '../../lib/hooks/use-password-execution-steps';
import styles from './input-auth.module.scss';

interface IInputPasswordProps {
  error?: boolean
  placeholder: string;
  label: string;
  onChange: (...event: any[]) => void
  value: string;
  uncheck?: boolean

}

const InputPassword = forwardRef(({
  error, placeholder, label, onChange, uncheck, value, ...filedProps
}: IInputPasswordProps, ref: Ref<HTMLInputElement>) => {
  const { passwordExecutionSteps, resetValidationSteps } = usePasswordExecutionSteps();
  const [isShown, setIsShown] = useState(false);

  const handleOnChange = (...events: BaseSyntheticEvent[]) => {
    events.forEach((event) => {
      onChange(event);

      if (!uncheck) passwordExecutionSteps(event.target.value);
    });
  };
  useEffect(() => () => resetValidationSteps(), []);

  return (
    <div className={classNames(styles.formGroup, { [styles.error]: error })}>
      <label>{label}</label>
      <input
        className="mask"
        ref={ref}
        type={isShown ? 'text' : 'password'}
        placeholder={placeholder}
        value={value}
        onChange={handleOnChange}
        {...filedProps}
      />

      <button tabIndex={-1} className={styles.viewPassword} type="button" onClick={() => setIsShown(!isShown)}>
        {!isShown ? <EyeClosedSvg /> : <EyeOpenedSvg />}
      </button>
    </div>
  );
});

export default InputPassword;
