import classNames from 'classnames';
import CountUp from 'react-countup';
import {
  memo, useEffect, useMemo, useRef, useState,
} from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { EStatus } from 'src/shared/types/global-types';
import { useAppDispatch } from 'src/app/store/store';
import { ERROR_MSG_LIST } from 'src/pages/profile/components/slider/slides/bonus-slide/components/bonus-my-bonuses/constants';
import { HIDDEN_BALANCES } from 'src/shared/constants/constants';
import { selectHiddenBalance } from 'src/pages/settings/model/selectors';
import { toFixedDecimalPrecision } from 'src/shared/libs/helpers/helper.lib';
import useAlert from 'src/shared/libs/hooks/use-alert';
import Button from 'src/shared/ui/button/button/button';
import useExchange from 'src/shared/libs/hooks/use-exchange';
import InputForm from 'src/shared/ui/input/input-form/input-form';
import SelectSkeleton from 'src/shared/ui/skeleton/select-skeleton/select-skeleton';
import { selectReferralsStatistics, selectStatusBonusesWithddraw } from '../../model/selectors';
import { BonusesWithdrawParams, fetchBonusesWithdraw, fetchReferralsStatistics } from '../../model/thunk';
import styles from './referrals-statistics.module.scss';

const ReferralsStatistics = () => {
  const { setAlertMessage } = useAlert();
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const { getExchangeImageById, getExchangeNameById } = useExchange();
  const inputRef = useRef<HTMLInputElement>(null);

  const balanceVisible = useSelector(selectHiddenBalance);
  const statistics = useSelector(selectReferralsStatistics);
  const statusBonusesWithdtaw = useSelector(selectStatusBonusesWithddraw);

  const [quantity, setQuantity] = useState('');
  const [selectedExchangeId, setSelectedExchangeId] = useState<number | null>(null);

  const isBlockedWithdraw = +quantity < 10 || !selectedExchangeId;
  const totalBonusesBalance = useMemo(() => statistics?.balances?.reduce((acc, item) => acc + Number(item.value), 0) || 0, [statistics]) || 0;

  const handleBonusesWithdraw = async () => {
    if (!selectedExchangeId) return;

    try {
      const params: BonusesWithdrawParams = {
        exchange_id: selectedExchangeId,
        quantity,
      };
      const { payload } = await dispatch(fetchBonusesWithdraw(params));

      if (typeof payload === 'string') throw payload;

      setAlertMessage('withdrawal_successful', 'success');
    } catch (error) {
      if (typeof error === 'string') {
        const message = ERROR_MSG_LIST[error] || ERROR_MSG_LIST.DEFAULT;
        setAlertMessage(message, 'error');
      } else {
        setAlertMessage(ERROR_MSG_LIST.DEFAULT, 'error');
      }
    }
  };

  const handleSetSelectedExchange = (id: number) => {
    setSelectedExchangeId((prev) => (prev === id ? null : id));

    if (id !== selectedExchangeId) {
      inputRef?.current?.focus();
    }
  };

  const handleOnClickTotalBalance = () => {
    if (selectedExchangeId && statistics) {
      const withwrawExchange = statistics.balances.find((exchange) => exchange.exchange_id === selectedExchangeId);

      if (withwrawExchange) setQuantity(toFixedDecimalPrecision(withwrawExchange.value));
      inputRef?.current?.focus();
    }
  };

  useEffect(() => {
    dispatch(fetchReferralsStatistics());
  }, []);

  return (
    <div className={styles.statistics}>
      <div className={styles.widgetContainer}>
        <h3 className={styles.title}>{t('balance_bonuses')}</h3>
        <p className={classNames(styles.value, 'mask')}>
          {!balanceVisible ? <CountUp decimal="." decimals={2} end={totalBonusesBalance} /> : HIDDEN_BALANCES}
        </p>

        <ul className={styles.exchanges}>
          {statistics?.balances?.map((exchange) => (
            <li
              role="button"
              tabIndex={0}
              onKeyDown={(e) => {
                if (e.code === 'Space' || e.code === 'Enter') {
                  handleSetSelectedExchange(exchange.exchange_id);
                }
              }}
              onClick={() => handleSetSelectedExchange(exchange.exchange_id)}
              key={exchange.exchange_id}
              className={classNames(styles.exchangeItem, { [styles.selected]: selectedExchangeId === exchange.exchange_id })}
            >
              <img src={getExchangeImageById(exchange.exchange_id)} alt="exchange" />
              <span className={styles.exchangeTitle}>{getExchangeNameById(exchange.exchange_id)}</span>
              <span className={classNames(styles.value, 'mask')}>{!balanceVisible ? exchange.value : HIDDEN_BALANCES}</span>
            </li>
          ))}

          {!statistics && [1, 2].map((skeleton) => <SelectSkeleton key={skeleton} height={41.33} className={styles.skeleton} />)}
        </ul>

        <InputForm title={t('quantity_count')}>
          <div className={styles.quantity}>
            <input className="mask" value={quantity} onChange={(e) => setQuantity(e.target.value)} ref={inputRef} type={!balanceVisible ? 'number' : 'password'} placeholder={t('minimum_10_usdt')} />
            <button onClick={handleOnClickTotalBalance}>{t('all')}</button>
          </div>
        </InputForm>
        <Button
          loading={statusBonusesWithdtaw === EStatus.loading}
          disabled={statusBonusesWithdtaw === EStatus.loading || isBlockedWithdraw}
          onClick={handleBonusesWithdraw}
          className={styles.btnWithdraw}
          background={isBlockedWithdraw ? 'black' : 'green'}
        >{t('withdraw_funds')}
        </Button>

        <p className={styles.bonusesWithdrawHint}>
          {t('bonuses_withdraw_hint')}
        </p>
      </div>

      <div className={styles.widgetContainer}>
        <h3 className={styles.title}>{t('widget_register')}</h3>
        <p className={classNames(styles.value, 'mask')}>
          {!balanceVisible ? <CountUp decimal="." end={Number(statistics?.registrations || 0)} /> : HIDDEN_BALANCES}
        </p>
      </div>

      <div className={styles.widgetContainer}>
        <h3 className={styles.title}>{t('widget_traders')}</h3>
        <p className={classNames(styles.value, 'mask')}>
          {!balanceVisible ? <CountUp decimal="." end={Number(statistics?.traders || 0)} /> : HIDDEN_BALANCES}
        </p>
      </div>

      <div className={styles.widgetContainer}>
        <h3 className={styles.title}>{t('widget_profit_usdt')}</h3>
        <p className={classNames(styles.value, 'mask')}>
          {!balanceVisible ? (
            <CountUp
              decimal="."
              end={Number(statistics?.profit || 0)}
            />
          ) : HIDDEN_BALANCES}
        </p>
      </div>

    </div>
  );
};

export default memo(ReferralsStatistics);
