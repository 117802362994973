import { useSelector } from 'react-redux';
import { selectHiddenBalance } from 'src/pages/settings/model/selectors';
import { HIDDEN_BALANCES, USDT_PRECISION } from 'src/shared/constants/constants';
import { EStatus } from 'src/shared/types/global-types';
import { LoadingOverlay } from '@mantine/core';
import { toFixedDecimalPrecision } from 'src/shared/libs/helpers/helper.lib';
import styles from './bonus-info-mini.module.scss';

interface BonusInfoMiniProps {
    title: string,
    value: string,
    status: EStatus,
}

const BonusInfoMini = ({ title, value, status }: BonusInfoMiniProps) => {
  const balanceVisible = useSelector(selectHiddenBalance);

  const checkValue = typeof value === 'string' ? value : '0.000';

  return (
    <div className={styles.infoMiniContainer}>
      <p>{title}</p>
      {
        status === EStatus.loading ? (
          <LoadingOverlay
            className="loader"
            visible
            zIndex={1000}
            overlayProps={{ radius: 'sm', blur: 2 }}
            loaderProps={{ color: '#00C37C', type: 'dots' }}
          />
        ) : <span className="mask">{!balanceVisible ? `≈ ${toFixedDecimalPrecision(checkValue, USDT_PRECISION)} USDT` : HIDDEN_BALANCES}</span>
      }
    </div>
  );
};

export default BonusInfoMini;
