import {
  useRef, useState, useEffect, memo, useLayoutEffect,
} from 'react';
import {
  getFromLocalStorage, join, removeItemFromLocalStorage, saveToLocalStorage, timeElapsedInSeconds,
} from 'src/shared/libs/helpers/helper.lib';

import { ReactComponent as ArrowSvg } from 'src/shared/assets/images/arrow.svg';
import { ReactComponent as CloseXIconSvg } from 'src/shared/assets/images/trading/closeX.svg';
import { ReactComponent as OptionIconSvg } from 'src/shared/assets/images/option.svg';
import InputText from 'src/shared/ui/input/input-text/input-text';

import './select.scss';
import { TIMER_SEND_SMS } from 'src/shared/constants/constants';
import { useTranslation } from 'react-i18next';
import Button from '../button/button/button';

export interface ISelectProps {
  isActiveSearch: boolean;
  children: React.ReactNode[];
  searchValue?: string;
  isSelectValue?: any
  isSearch?: (value: string) => void;
  onClick?: () => void
  darkStyle?: boolean
  disabledSelect?:boolean
  startTimer?: boolean
  stopTimer?: (status: boolean) => void
  timerId?: string
  className?: string
  classNameSelect?: string
  position?: 'top' | 'bottom'
  onRemove?: () => void
  isRemoveVisible?: unknown
  isOption?: boolean
  fromOptionValue?: string
  toOptionValue?: string
  onChangeFromOption?: (value: string) => void
  onChangeToOption?: (value: string) => void
  onSubmitOption?: () => void
  onKeyDown?: (event: React.KeyboardEvent<HTMLInputElement>) => void
}

const Select = memo(({
  children, isActiveSearch, searchValue, className, classNameSelect, isSearch, onClick, isSelectValue, darkStyle, disabledSelect, startTimer, position, onRemove, isRemoveVisible,
  stopTimer, isOption = false, fromOptionValue, toOptionValue, onChangeFromOption, onChangeToOption, onSubmitOption, onKeyDown,
  timerId,
}: ISelectProps) => {
  const selectContainerRef = useRef<HTMLDivElement>(null);
  const [selectedValue, setSelectedValue] = useState(children[0]);
  const [isActiveSelection, setIsActiveSelection] = useState(false);
  const [isActiveOption, setIsActiveOption] = useState(false);
  const [search, setSearch] = useState('');
  const { t } = useTranslation();

  // timer
  const timer = useRef<number>(TIMER_SEND_SMS);
  const intervalRef = useRef<ReturnType<typeof setTimeout> | null>(null);

  const [remainingTime, setRemainingTime] = useState(TIMER_SEND_SMS);
  const [timerVisible, setTimerVisible] = useState(false);

  const minutes = String(Math.floor(remainingTime / 60)).padStart(2, '0');
  const seconds = String(remainingTime % 60).padStart(2, '0');

  const setSelectedValueLocal = (value: typeof children[0]) => {
    setIsActiveSelection(false);
    setSelectedValue(value);
  };

  const handleOnRemove = (event: React.MouseEvent<SVGSVGElement, MouseEvent>) => {
    event.stopPropagation();

    if (onRemove) onRemove();
    if (onChangeFromOption) onChangeFromOption('');
    if (onChangeToOption) onChangeToOption('');
  };
  const handleOption = (event: React.MouseEvent<SVGSVGElement, MouseEvent>) => {
    event.stopPropagation();

    if (disabledSelect) return;

    setIsActiveSelection(false);
    setIsActiveOption((prev) => !prev);
  };

  const handleSetIsActiveSelection = () => {
    if (disabledSelect) return;

    setIsActiveSelection((prev) => !prev);
    setIsActiveOption(false);
  };

  const handleOnSubmitOption = () => {
    if (onSubmitOption) onSubmitOption();
    setIsActiveOption(false);
  };

  useEffect(() => {
    // set the timer time and display it
    if (startTimer) {
      timer.current = TIMER_SEND_SMS;
      setRemainingTime(TIMER_SEND_SMS);
      setTimerVisible(true);
    }
  }, [startTimer]);

  useLayoutEffect(() => {
    if (timerId) {
      // Get the remaining time from localStorage
      const remainingTimeFromLocalStorage = getFromLocalStorage(timerId, null);

      // Check if there is remaining time in localStorage
      if (remainingTimeFromLocalStorage) {
        // Calculate elapsed time in seconds
        const seconds = timeElapsedInSeconds(remainingTimeFromLocalStorage);

        // Check if the timer has expired
        const endTimer = (seconds - TIMER_SEND_SMS) < 0;

        if (!endTimer) {
          // If the timer has expired, update state and clear localStorage
          timer.current = 0;
          setRemainingTime(0);
          setTimerVisible(false);
          removeItemFromLocalStorage(timerId);
        } else {
          // If the timer has not expired, update state considering the remaining time
          setRemainingTime(TIMER_SEND_SMS - seconds);
          timer.current = TIMER_SEND_SMS - seconds;
        }
      }
    }
  }, [timerId]);

  useEffect(() => {
    if (timerId) {
      // Get the remaining time from localStorage
      const remainingTimeFromLocalStorage = getFromLocalStorage(timerId, null);

      // Check if timerId is set and either startTimer is true or there is remaining time in localStorage
      if (timerId && (startTimer || remainingTimeFromLocalStorage)) {
        // If there is no remaining time in localStorage, save the current timestamp
        if (!remainingTimeFromLocalStorage) {
          saveToLocalStorage(timerId, Date());
        }
        // Make the timer visible
        setTimerVisible(true);
        // Set up an interval to update the timer every second
        intervalRef.current = setInterval(() => {
          // If there is remaining time in the timer
          if (timer.current && timer.current > 0) {
            // Decrement the timer and update the remaining time
            timer.current -= 1;
            setRemainingTime(timer.current);
          } else {
            // If the timer has reached zero, hide the timer, remove from localStorage, and clear the interval
            if (stopTimer) {
              stopTimer(false);
            }
            setTimerVisible(false);
            removeItemFromLocalStorage(timerId);
            clearInterval(intervalRef.current as ReturnType<typeof setTimeout>);
          }
        }, 1000);
      }
    }

    // Cleanup function to clear the interval when the component is unmounted or dependencies change
    return () => {
      clearInterval(intervalRef.current as ReturnType<typeof setTimeout>);
    };
  }, [startTimer, timerId]);

  useEffect(() => {
    const checkClickOutsideHandler = (e: MouseEvent) => {
      e.stopPropagation();

      if (!selectContainerRef.current?.contains(e.target as Node) && (isActiveSelection || isActiveOption)) {
        setIsActiveSelection(false);
        setIsActiveOption(false);
      }
    };
    window.addEventListener('click', checkClickOutsideHandler);

    return () => {
      window.removeEventListener('click', checkClickOutsideHandler);
    };
  }, [isActiveSelection, isActiveOption]);

  return (
    <div
      ref={selectContainerRef}
      className={join(classNameSelect && classNameSelect, 'select', isActiveSelection && 'select_active', darkStyle && 'dark-style')}
      role="button"
      tabIndex={0}
      onKeyDown={() => {}}
      onClick={onClick}
    >
      <div
        role="button"
        tabIndex={0}
        onKeyDown={() => {}}
        className="selected-item"
        onClick={handleSetIsActiveSelection}
      >
        <div className="item-content">{
          isSelectValue || selectedValue
        }
        </div>
        {timerVisible && <time>{`${minutes}:${seconds}`}</time>}
        {Boolean(isRemoveVisible) && (<CloseXIconSvg onClick={handleOnRemove} className="select-remove-icon" />)}
        {!disabledSelect && <ArrowSvg className="select-arrow-icon" />}
        {isOption && <OptionIconSvg onClick={handleOption} className="select-option-icon" />}

      </div>

      <div className={join('search-container', position && `position-${position}`)}>
        {
          isActiveSearch
            && (
              <div className={join('search-form', darkStyle && 'search-dark-style')}>
                <InputText
                  value={searchValue || search}
                  placeholder={t('search')}
                  onChange={isSearch || setSearch}
                />
              </div>
            )
        }

        {children.length > 0 && (
          <ul className={join('search-items', className)}>
            { children.map((element, index) => (
              <li
                role="button"
                tabIndex={0}
                onKeyDown={() => {}}
                key={index}
                className="item-content"
                onClick={() => setSelectedValueLocal(element)}
              >{element}
              </li>
            ))}
          </ul>
        )}
      </div>

      <div className={join('option-container')}>
        { isActiveOption && onChangeToOption && onChangeFromOption && (
          <div className="option-container-wrapper">
            <div className="option-input-wrapper">
              <label htmlFor="">{t('from')}:</label>
              <input
                value={fromOptionValue}
                onChange={(event) => onChangeFromOption(event.target.value)}
                type="number"
                onKeyDown={onKeyDown}
              />
            </div>

            <div className="option-input-wrapper">
              <label htmlFor="">{t('to')}:</label>
              <input
                value={toOptionValue}
                onChange={(event) => onChangeToOption(event.target.value)}
                type="number"
                onKeyDown={onKeyDown}
              />
            </div>
            <Button onClick={handleOnSubmitOption} background="green">{t('apply')}</Button>
          </div>
        )}
      </div>
    </div>
  );
});

export default Select;
