import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { SubAccountType } from 'src/app/store/slices/sub-accounts/types';
import { selectBalances } from 'src/app/store/slices/balance/selectors';
import { BalanceType } from 'src/entities/balance-type';
import { plusValues, toFixed } from 'src/shared/libs/helpers/helper.lib';
import { selectHiddenBalance } from 'src/pages/settings/model/selectors';
import { ETradingType, Nullable } from 'src/shared/types/global-types';
import { HIDDEN_BALANCES, USDT_PRECISION } from 'src/shared/constants/constants';
import useSubAccount from 'src/shared/libs/hooks/use-sub-account-name';
import useExchange from 'src/shared/libs/hooks/use-exchange';
import styles from './account-balances.module.scss';

interface IAccountBalancesProps {
    subAccounts: Nullable<SubAccountType[]>
}

const AccountBalances = ({ subAccounts } : IAccountBalancesProps) => {
  const { t } = useTranslation();
  const { getSubAccountExchangeId } = useSubAccount();
  const { getExchangeNameById } = useExchange();

  const { balances } = useSelector(selectBalances);
  const balanceVisible = useSelector(selectHiddenBalance);

  const balance = useMemo(() => {
    const { pathname } = window.location;
    const [_, id] = pathname.split('/sub-accounts/');

    if (balances) {
      const currentBalance = balances.sub_accounts.find((item) => item.sub_account_id === Number(id));

      return currentBalance || null;
    }

    return null;
  }, [balances]);

  const currentExchangeName = useMemo(() => {
    if (balance) {
      const subAccountExhcangeId = getSubAccountExchangeId(balance.sub_account_id);

      if (subAccountExhcangeId) {
        return getExchangeNameById(subAccountExhcangeId) || null;
      }
    }
    return null;
  }, [balance]);

  return (
    <div className={styles.balancesWrapper}>
      {subAccounts
                && (
                  <div className={styles.subAccount}>
                    <div className={styles.info}>
                      <div className={styles.totalQuantity}>
                        <p>{t('total_balance')}:</p>
                        <span className="mask">{balance ? !balanceVisible ? parseFloat(toFixed(plusValues(String(balance.spot), String(balance.futures)), USDT_PRECISION)) : HIDDEN_BALANCES : 0} USDT
                          {currentExchangeName === 'Bybit' && <BalanceType type={ETradingType.unified} />}
                        </span>
                      </div>

                      <div className={styles.accounts}>
                        <div className={styles.quantity}>
                          {currentExchangeName === 'Bybit' ? <p>{t('margin_assets')}:</p> : <p>{t('futures_account')}:</p>}
                          <div className={styles.balance}>
                            <span className="mask">{balance ? !balanceVisible ? parseFloat(toFixed(String(balance.futures), USDT_PRECISION)) : HIDDEN_BALANCES : '0.0'} USDT</span>
                            <BalanceType type={ETradingType.futures} />
                          </div>
                        </div>

                        <div className={styles.quantity}>
                          {currentExchangeName === 'Bybit' ? <p>{t('free_assets')}:</p> : <p>{t('spot_account')}:</p>}

                          <div className={styles.balance}>
                            <span className="mask">{balance ? !balanceVisible ? parseFloat(toFixed(String(balance.spot), USDT_PRECISION)) : HIDDEN_BALANCES : '0.0'} USDT</span>
                            <BalanceType type={ETradingType.spot} />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
    </div>
  );
};

export default AccountBalances;
