import axios, { AxiosError } from 'axios';
import { BASE_URL } from 'src/shared/constants/constants';
import { getTokensFromLocalStorage } from 'src/shared/libs/helpers/save-tokens';
import TelegramLogService, { TelegramLogData } from 'src/services/telegram-log.service';
import { getUserEmail, removeTokensFromLocalStorage } from '../libs/helpers/helper.lib';

export const loggingTelegram = (error: AxiosError) => {
  try {
    if (error.response?.config.url === '/api/volume-screener/download-release') return;

    // Network error
    if (!error.response) {
      if (error.message === 'canceled' || error.message === 'Request aborted') return;

      const logData: TelegramLogData = {
        url: String(error?.config?.url),
        message: error.message,
        status: 0,
        data: null,
        user: getUserEmail() || 'Не авторизован',
      };

      TelegramLogService.log(logData);
      return;
    }

    if (error.response.config.url) {
      const ignorePath = ['login', 'registration', 'forgot-password', 'step1', 'step2', 'logs'];
      const containsIgnoredPath = ignorePath.some((path) => error.response?.config?.url?.includes(path));

      if (containsIgnoredPath) return;
    }

    const errorData = typeof error.response.config.data === 'object' ? JSON.parse(error.response.config.data) : error.response.config.data;

    const logData: TelegramLogData = {
      url: String(error.response.config.url),
      // @ts-ignore
      message: String(error.response.data?.detail || error.response.data),
      status: error.response.status,
      data: errorData,
      user: getUserEmail(),
    };

    TelegramLogService.log(logData);
  } catch (error) {
    console.debug('error service TelegramoLogService', error);
    throw error;
  }
};

export const $api = axios.create({
  baseURL: BASE_URL,
});

$api.interceptors.request.use(
  async (config) => {
    const tokens = getTokensFromLocalStorage();

    if (tokens && tokens?.access_token) {
      config.headers.Authorization = `Bearer ${tokens?.access_token}`;
    }

    return config;
  },
  (error) => Promise.reject(error),
);

$api.interceptors.response.use(
  (response) => response,
  async (error) => {
    const errorStatus = error.response?.status;

    if (errorStatus === 401) {
      removeTokensFromLocalStorage();
      window.location.href = '/auth/login';
    }

    if (errorStatus !== 429 && (errorStatus === 400 || error.response?.status > 401)) {
      loggingTelegram(error);
    }

    if (errorStatus === undefined) {
      loggingTelegram(error);
    }

    return error;
  },
);
