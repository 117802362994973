import moment from 'moment';
import {
  useState, useEffect, useMemo,
} from 'react';
import { useSelector } from 'react-redux';
import { useDisclosure } from '@mantine/hooks';
import { useTranslation } from 'react-i18next';
import { selectSubAccounts } from 'src/app/store/slices/sub-accounts/selectors';
import { PositionExchange } from 'src/pages/trading/model/types';
import { Nullable, SubAccountsModify } from 'src/shared/types/global-types';
import { createSubAccounts, getDate } from 'src/shared/libs/helpers/helper.lib';

import { selectRebate } from 'src/app/store/slices/bonus/selectors';
import { useAppDispatch } from 'src/app/store/store';
import { RebateStatisticsParams, fetchRebateStatistics } from 'src/app/store/slices/bonus/thunks';

import useAlert from 'src/shared/libs/hooks/use-alert';
import useMobile from 'src/shared/libs/hooks/use-mobile';
import useExchange from 'src/shared/libs/hooks/use-exchange';
import BonusParams from './components/bonus-params/bonus-params';
import BonusInfoMini from './components/bonus-info-mini/bonus-info-mini';
import BonusMyBonuses from './components/bonus-my-bonuses/bonus-my-bonuses';
import RebateHistoryTable from './components/bonus-history-table/rebate-history-table';
import BonusParamsAdaptive from './components/bonus-params/adaptive/bonus-params-adaptive';
import RebateHistoryTableAdaptive from './components/bonus-history-table/adaptive/rebate-history-table-adaptive';

import './bonus-slide.scss';

const BonusSlide = () => {
  const dispatch = useAppDispatch();
  const isMobile = useMobile();
  const { t } = useTranslation();
  const { setAlertMessage } = useAlert();
  const { availableExchanges: exchanges } = useExchange();

  const subAccounts = useSelector(selectSubAccounts);
  const { rebateStatistics, statusRebateStatistics } = useSelector(selectRebate);
  const [openedTableFilter, { open: openTableFilter, close: closeTableFilter }] = useDisclosure(false);

  const subAccountsList = useMemo(() => createSubAccounts(subAccounts), [subAccounts]);

  const [currentExchange, setCurrentExchange] = useState<Nullable<PositionExchange>>(null);
  const [currentSubAccount, setCurrentSubAccount] = useState <Nullable<SubAccountsModify>>(null);

  const { currentDateAsString, sevenDaysAgoAsString } = getDate();
  const [dateRange, setDateRange] = useState<any>([sevenDaysAgoAsString, currentDateAsString]);
  const [startDate, endDate] = dateRange;

  const handleFetchRebateStatistics = async () => {
    if (isMobile) return;

    if (currentExchange && currentSubAccount && startDate && endDate) {
      const rebateStatisticsParams: RebateStatisticsParams = {
        from_date: moment(startDate).startOf('day').format('YYYY-MM-DD HH:mm:ss'),
        to_date: moment(endDate).endOf('day').format('YYYY-MM-DD HH:mm:ss'),
        exchange_id: currentExchange.id,
        sub_account_id: currentSubAccount.id,
        trading_type: currentSubAccount.type,
      };

      const { payload } = await dispatch(fetchRebateStatistics(rebateStatisticsParams));

      if (typeof payload === 'string') setAlertMessage(payload, 'error');
    }
  };

  const handleResetFilter = () => {
    const currentDate = moment();
    const startDateCurrent = currentDate.clone().subtract(7, 'days');

    const start = startDateCurrent.startOf('day').format('YYYY-MM-DD HH:mm:ss');
    const end = currentDate.endOf('day').format('YYYY-MM-DD HH:mm:ss');

    const checkStartDate = moment(startDate).format('YYYY-MM-DD');
    const checkEndDate = moment(endDate).format('YYYY-MM-DD');

    if (checkStartDate === moment(start).format('YYYY-MM-DD') && checkEndDate === moment(end).format('YYYY-MM-DD')) return;

    setDateRange([start, end]);
  };

  const handleSetCurrentExchange = (exchange: PositionExchange) => {
    setCurrentExchange(exchange);

    const [firstExchange] = subAccountsList.filter((subAccount) => subAccount.exchange_id === exchange.id);
    if (firstExchange) setCurrentSubAccount(firstExchange);
  };

  const handleSetRebateParams = async () => {
    if (currentExchange && currentSubAccount && startDate && endDate) {
      const rebateStatisticsParams: RebateStatisticsParams = {
        from_date: moment(startDate).startOf('day').format('YYYY-MM-DD HH:mm:ss'),
        to_date: moment(endDate).endOf('day').format('YYYY-MM-DD HH:mm:ss'),
        exchange_id: currentExchange.id,
        sub_account_id: currentSubAccount.id,
        trading_type: currentSubAccount.type,
      };
      closeTableFilter();

      try {
        dispatch(fetchRebateStatistics(rebateStatisticsParams));
      } catch (error) {
        setAlertMessage(JSON.stringify(error), 'error');
      }
    }
  };

  useEffect(() => {
    if (!exchanges || !subAccounts) return;

    const favoriteSubAccount = subAccounts.find((subAccount) => subAccount.is_favorite);
    const exchangeByFavoriteSubAccount = exchanges.find((exchange) => exchange.id === favoriteSubAccount?.exchange_id);
    const [firstElement] = exchanges;

    setCurrentExchange(exchangeByFavoriteSubAccount || firstElement);
  }, [exchanges, subAccounts]);

  useEffect(() => {
    setCurrentSubAccount(subAccountsList?.[0] || null);
  }, [subAccountsList]);

  useEffect(() => {
    if (currentExchange && currentSubAccount && startDate && endDate) handleFetchRebateStatistics();
  }, [currentExchange, currentSubAccount, startDate, endDate]);

  return (
    <div className="slide bonus-slide">
      <div className="bonus-slide-body">
        { !isMobile && (
          <BonusParams
            removeData={() => {}}
            setDateRange={setDateRange}
            dateRange={dateRange}
            exchanges={exchanges}
            currentExchange={currentExchange}
            setCurrentExchange={handleSetCurrentExchange}
            subAccounts={subAccountsList}
            currentSubAccount={currentSubAccount}
            setCurrentSubAccount={setCurrentSubAccount}
            resetFilters={handleResetFilter}
          />
        )}
        {isMobile && (
          <BonusParamsAdaptive
            openedDrawer={openedTableFilter}
            openDrawer={openTableFilter}
            closeDrawer={closeTableFilter}
            removeData={() => {}}
            setDateRange={setDateRange}
            dateRange={dateRange}
            exchanges={exchanges}
            currentExchange={currentExchange}
            setCurrentExchange={handleSetCurrentExchange}
            subAccounts={subAccountsList}
            currentSubAccount={currentSubAccount}
            setCurrentSubAccount={setCurrentSubAccount}
            resetFilters={handleResetFilter}
            setRebateParams={handleSetRebateParams}
          />
        )}

        {!isMobile && (
          <div className="info-mini-wrapper">
            <BonusInfoMini status={statusRebateStatistics} title={t('exchange fee')} value={rebateStatistics?.commissions_amount || '0'} />
            <BonusInfoMini status={statusRebateStatistics} title={t('bonus payout')} value={rebateStatistics?.rebates_amount || '0'} />
            <BonusInfoMini status={statusRebateStatistics} title={t('next level')} value="0" />
          </div>
        )}

        {isMobile && (
          <div className="info-mini-wrapper-adaptive">
            <div className="info-mini-wrapper-box">
              <BonusInfoMini status={statusRebateStatistics} title={t('exchange fee')} value={rebateStatistics?.commissions_amount || '0'} />
              <BonusInfoMini status={statusRebateStatistics} title={t('bonus payout')} value={rebateStatistics?.rebates_amount || '0'} />
            </div>
            <BonusInfoMini status={statusRebateStatistics} title={t('next level')} value="0" />
          </div>
        )}

        {isMobile ? <RebateHistoryTableAdaptive /> : <RebateHistoryTable />}
      </div>
      <div className="bonus-slide-aside">
        <BonusMyBonuses />
      </div>
    </div>
  );
};

export default BonusSlide;
