import moment from 'moment';
import classNames from 'classnames';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { BalanceType } from 'src/entities/balance-type';
import { RebateHistory } from 'src/app/store/slices/bonus/types';
import { HIDDEN_BALANCES } from 'src/shared/constants/constants';
import { selectHiddenBalance } from 'src/pages/settings/model/selectors';
import useSubAccount from 'src/shared/libs/hooks/use-sub-account-name';
import styles from './rebate-item.module.scss';

interface IRebateItemProps {
    rebateHistory: RebateHistory
}

const RebateItem = ({ rebateHistory } : IRebateItemProps) => {
  const { t } = useTranslation();
  const { getSubAccountNameById } = useSubAccount();
  const balanceVisible = useSelector(selectHiddenBalance);

  return (
    <div className={styles.rebateItem}>
      <div className={styles.horizontalWrapper}>
        <div className={styles.boxVertical}>
          <p className={classNames(styles.quantity, 'mask')}>{!balanceVisible ? rebateHistory.quantity : HIDDEN_BALANCES} {rebateHistory.asset.symbol}</p>
          <time>{moment(rebateHistory.created_at).format('YYYY-MM-DD HH:mm')}</time>

        </div>

        <img className={styles.exchangeImage} src={rebateHistory.exchange.image} alt="exchange" />
      </div>

      <div className={styles.box}>
        <span className={styles.boxTitle}>{t('account')}</span>
        <div className={styles.subAccount}>
          {getSubAccountNameById(rebateHistory.sub_account_id)}
          <BalanceType type={rebateHistory.trading_type} />
        </div>
      </div>

      <div className={styles.box}>
        <span>{t('exchange fee')}</span>
        <p className="mask">{!balanceVisible ? rebateHistory.commission : HIDDEN_BALANCES} USDT</p>
      </div>
    </div>
  );
};

export default RebateItem;
