import { memo } from 'react';
import { useTheme } from 'src/shared/libs/hooks/use-theme';
import { EThemeMode } from 'src/app/store/slices/user/types';
import { BalanceType } from 'src/entities/balance-type';
import { useTranslation } from 'react-i18next';
import { PositionExchange } from 'src/pages/trading/model/types';
import { Nullable, SubAccountsModify } from 'src/shared/types/global-types';
import Select from 'src/shared/ui/select/select';
import Button from 'src/shared/ui/button/button/button';
import InputForm from 'src/shared/ui/input/input-form/input-form';
import SelectSkeleton from 'src/shared/ui/skeleton/select-skeleton/select-skeleton';
import DataPickerRange from 'src/shared/ui/date/data-range-picker';
import styles from './bonus-params.module.scss';

interface IBonusParamsProps {
  dateRange: string[];
  exchanges: Nullable<PositionExchange[]>
  subAccounts: SubAccountsModify[]
  currentExchange: Nullable<PositionExchange>
  currentSubAccount: Nullable<SubAccountsModify>
  removeData: () => void;
  resetFilters: () => void;
  setDateRange: (data: string[]) => void;
  setCurrentExchange: (value: PositionExchange) => void
  setCurrentSubAccount: (value: Nullable<SubAccountsModify>) => void
}

const BonusParams = memo(({
  removeData, setDateRange, dateRange, currentExchange, exchanges, subAccounts, currentSubAccount, setCurrentSubAccount, setCurrentExchange, resetFilters: resetAction,
}: IBonusParamsProps) => {
  const { theme } = useTheme();
  const { t } = useTranslation();

  const handleSetCurrentExchange = (exchange: PositionExchange) => {
    setCurrentSubAccount(null);
    setCurrentExchange(exchange);
  };

  return (
    <section className={styles.container}>
      <h2>{t('statistics')}</h2>

      <div className={styles.params}>
        <DataPickerRange whiteMode={theme === EThemeMode.LIGHT} removeData={removeData} setPage={() => {}} setDateRange={setDateRange} dateRange={dateRange} />

        <InputForm maxWidth="150px" titleColor="white" title={t('exchange')}>
          { exchanges && currentExchange ? (
            <Select
              isActiveSearch={false}
              isSelectValue={(
                <div className={styles.selectCurrentItem}>
                  <img src={currentExchange.image} alt="exchange" />
                  <span className="short-name">{currentExchange.name}</span>
                </div>
              )}
            >
              {
                exchanges.map((exchange) => (
                  <div
                    key={exchange.id}
                    className={styles.selectItem}
                    role="button"
                    tabIndex={0}
                    onClick={() => handleSetCurrentExchange(exchange)}
                    onKeyDown={() => {}}
                  >
                    <img src={exchange.image} alt="exchange" />
                    <span className="short-name">{exchange.name}</span>
                  </div>
                ))
              }
            </Select>
          ) : <SelectSkeleton />}
        </InputForm>

        <InputForm minWidth="250" maxWidth="250px" title={t('account')}>
          {subAccounts && currentSubAccount ? (
            <Select
              isActiveSearch={false}
              isSelectValue={(
                <div className={styles.selectCurrentItem}>
                  <BalanceType type={currentSubAccount.type} />
                  {currentSubAccount.name}
                </div>
              )}
            >
              {subAccounts.filter((subAccount) => subAccount.exchange_id === currentExchange?.id).map((subAccount) => (
                <div
                  key={subAccount.id}
                  className={styles.selectItem}
                  role="button"
                  tabIndex={0}
                  onKeyDown={() => setCurrentSubAccount(subAccount)}
                  onClick={() => setCurrentSubAccount(subAccount)}
                >
                  <BalanceType type={subAccount.type} />
                  {subAccount.name}
                </div>
              ))}
            </Select>
          ) : <SelectSkeleton />}
        </InputForm>

        <div className={styles.action}>
          <Button minWidth="150px" onClick={resetAction} background={theme === EThemeMode.DARK ? 'white' : 'black'}>{t('reset filters')}</Button>
        </div>
      </div>
    </section>
  );
});

export default BonusParams;
