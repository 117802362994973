import { useTranslation } from 'react-i18next';
import Button from 'src/shared/ui/button/button/button';
import { ReactComponent as HamsterError } from 'src/shared/assets/images/hamster-error-hover1.svg';
import styles from './disconnected-ws-modal-content.module.scss';

const DisconnectedWsModalContent = () => {
  const { t } = useTranslation();

  return (
    <div className={styles.content}>
      <HamsterError />
      <h3>{t('connection')} <br /> {t('was_interrupted')}</h3>
      <p>{t('please_refresh_page')}, <br /> {t('restore_connection')}</p>
      <Button onClick={() => window.location.reload()} maxWidth="200px" background="green">{t('refresh_page')}</Button>
    </div>
  );
};

export default DisconnectedWsModalContent;
