import classNames from 'classnames';
import {
  useLayoutEffect, useMemo, useRef, useState,
} from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { unwrapResult } from '@reduxjs/toolkit';
import { useAppDispatch } from 'src/app/store/store';
import { EStatus } from 'src/shared/types/global-types';
import { selectRebate } from 'src/app/store/slices/bonus/selectors';
import { HIDDEN_BALANCES } from 'src/shared/constants/constants';
import { setAutoBonusConverting } from 'src/pages/settings/model/slice';
import { toFixedDecimalPrecision } from 'src/shared/libs/helpers/helper.lib';
import { fetchChangeTradeSettings } from 'src/pages/settings/model/thunks';
import { updaterebatesBonusesBalance } from 'src/app/store/slices/bonus/slice';
import { selectHiddenBalance, selectTradeSettings } from 'src/pages/settings/model/selectors';
import { fetchRebatesBonensBalance, fetchRebatesBonusesWithdraw, RebatesBonensWithdrawParams } from 'src/app/store/slices/bonus/thunks';
import Button from 'src/shared/ui/button/button/button';
import Toogler from 'src/shared/ui/toogler/toogler';
import useAlert from 'src/shared/libs/hooks/use-alert';
import useExchange from 'src/shared/libs/hooks/use-exchange';
import { ERROR_MSG_LIST } from './constants';
import styles from './bonus-my-bonuses.module.scss';

const BonusMyBonuses = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const { setAlertMessage } = useAlert();
  const { getExchangeImageById, getExchangeNameById } = useExchange();

  const balanceVisible = useSelector(selectHiddenBalance);
  const { settings } = useSelector(selectTradeSettings);
  const { rebatesBonusesBalance, statusRebatesBonusesBalance, statusRebatesBonusesWithdraw } = useSelector(selectRebate);

  const [quantity, setQuantity] = useState('');
  const [autoConvert, setAutoConvert] = useState(!!settings?.auto_bonus_converting);
  const [selectedExchangeId, setSelectedExchangeId] = useState<number | null>(null);
  const inputRef = useRef<HTMLInputElement>(null);

  const isBlockedWithdraw = +quantity < 10 || !selectedExchangeId;

  const totalBonusesBalance = useMemo(() => {
    if (!rebatesBonusesBalance?.balances) return '0.00';

    const precision = 2;
    const totalBalance = rebatesBonusesBalance.balances.reduce((sum, item) => sum + parseFloat(item.value), 0).toString();

    return toFixedDecimalPrecision(totalBalance, precision);
  }, [rebatesBonusesBalance]);

  const handleOnClickTotalBalance = () => {
    if (selectedExchangeId && rebatesBonusesBalance) {
      const withwrawExchange = rebatesBonusesBalance.balances.find((exchange) => exchange.exchange_id === selectedExchangeId);

      if (withwrawExchange) setQuantity(toFixedDecimalPrecision(withwrawExchange.value, 2));
    }
  };

  const handleSetSelectedExchange = (id: number) => {
    setSelectedExchangeId((prev) => (prev === id ? null : id));

    if (id !== selectedExchangeId) {
      inputRef?.current?.focus();
    }
  };

  const fetchChangeAutoConvert = async () => {
    setAutoConvert((prev) => !prev);
    try {
      const { payload } = await dispatch(fetchChangeTradeSettings({ auto_bonus_converting: !autoConvert }));

      if (payload === true) {
        dispatch(setAutoBonusConverting(!autoConvert));
      } else if (typeof payload !== 'boolean') {
        setAutoConvert((prev) => !prev);
        throw payload;
      }
    } catch (error: unknown) {
      if (typeof error === 'string') {
        const message = ERROR_MSG_LIST[error] || ERROR_MSG_LIST.DEFAULT;
        setAlertMessage(message, 'error');
      } else {
        setAlertMessage(ERROR_MSG_LIST.DEFAULT, 'error');
      }
    }
  };

  const fetchBonusesWithdraw = async () => {
    if (isBlockedWithdraw) return;

    try {
      const params: RebatesBonensWithdrawParams = {
        exchange_id: selectedExchangeId,
        quantity,
      };

      const response = await dispatch(fetchRebatesBonusesWithdraw(params));
      const payload = unwrapResult(response);

      if (payload === true) {
        dispatch(updaterebatesBonusesBalance(params));
        setAlertMessage('withdrawal_successful', 'success');
        return;
      }

      throw payload;
    } catch (error) {
      if (typeof error === 'string') {
        const message = ERROR_MSG_LIST[error] || ERROR_MSG_LIST.DEFAULT;
        setAlertMessage(message, 'error');
      } else {
        setAlertMessage(ERROR_MSG_LIST.DEFAULT, 'error');
      }
    }
  };

  useLayoutEffect(() => {
    dispatch(fetchRebatesBonensBalance());
  }, []);

  return (
    <section className={styles.container}>
      <div className={styles.title}>{t('my bonuses')}</div>
      <div className={styles.balance}>
        {t('current bonus balance')}
        <span className="mask">{!balanceVisible ? `${totalBonusesBalance} USDT` : HIDDEN_BALANCES}</span>
      </div>

      { autoConvert && (
        <ul className={styles.exchangesPreview}>
          {
            rebatesBonusesBalance?.balances.map((exchange) => (
              <li
                role="button"
                tabIndex={0}
                onKeyDown={(e) => {
                  if (e.code === 'Space' || e.code === 'Enter') {
                    handleSetSelectedExchange(exchange.exchange_id);
                  }
                }}
                onClick={() => handleSetSelectedExchange(exchange.exchange_id)}
                key={exchange.exchange_id}
                className={styles.exchange}
              >
                <img src={getExchangeImageById(exchange.exchange_id)} alt="exchange" />
                <span className={styles.exchangeTitle}>{getExchangeNameById(exchange.exchange_id)}</span>
                <span className={classNames(styles.value, 'mask')}>{!balanceVisible ? exchange.value : HIDDEN_BALANCES}</span>
              </li>
            ))
          }

        </ul>
      )}

      <Toogler isLoading={statusRebatesBonusesBalance === EStatus.loading} isActive={autoConvert} onChange={fetchChangeAutoConvert} labelContent={t('auto convert')} />
      {autoConvert && <p className={styles.info}>{t('the_conversion_is_carried_out_within_24_hours')}</p>}

      {!autoConvert && (
        <div className={styles.manualConservation}>
          <p className={styles.manualConservationTitle}>{t('manual bonus conversion')}</p>
          <p className={styles.info}>{t('select_account_convert')}</p>

          <ul className={styles.exchanges}>
            {
              rebatesBonusesBalance?.balances.map((exchange) => (
                <li
                  role="button"
                  tabIndex={0}
                  onKeyDown={(e) => {
                    if (e.code === 'Space' || e.code === 'Enter') {
                      handleSetSelectedExchange(exchange.exchange_id);
                    }
                  }}
                  onClick={() => handleSetSelectedExchange(exchange.exchange_id)}
                  key={exchange.exchange_id}
                  className={classNames(styles.exchangeItem, { [styles.selected]: selectedExchangeId === exchange.exchange_id })}
                >
                  <img src={getExchangeImageById(exchange.exchange_id)} alt="exchange" />
                  <span className={styles.exchangeTitle}>{getExchangeNameById(exchange.exchange_id)}</span>
                  <span className={classNames(styles.value, 'mask')}>{!balanceVisible ? exchange.value : HIDDEN_BALANCES}</span>
                </li>
              ))
            }

          </ul>
          <div className={styles.quantity}>
            <input className="mask" value={quantity} onChange={(e) => setQuantity(e.target.value)} ref={inputRef} type={!balanceVisible ? 'number' : 'password'} placeholder={t('minimum_10_usdt')} />
            <button onClick={handleOnClickTotalBalance}>{t('all')}</button>
          </div>
          <Button
            onClick={fetchBonusesWithdraw}
            disabled={statusRebatesBonusesWithdraw === EStatus.loading || isBlockedWithdraw}
            loading={statusRebatesBonusesWithdraw === EStatus.loading}
            background={!isBlockedWithdraw ? 'green' : 'gray'}
          >{t('convert')}
          </Button>

          <p className={styles.manualConservationHint}>{t('manual_conversion_hint')}</p>
        </div>
      )}
    </section>
  );
};
export default BonusMyBonuses;
