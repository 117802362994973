import className from 'classnames';
import { Link } from 'react-router-dom';
import { Tooltip } from '@mantine/core';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { toFixedDecimalPrecision } from 'src/shared/libs/helpers/helper.lib';
import { HIDDEN_BALANCES, USDT_PRECISION } from 'src/shared/constants/constants';
import { selectHiddenBalance } from 'src/pages/settings/model/selectors';
import { ReactComponent as ArrowIconSvg } from 'src/shared/assets/images/aside/arrow-right-top.svg';
import { ReactComponent as ReloadIconSvg } from 'src/shared/assets/images/reload.svg';
import { ETradingType, Nullable, TradingType } from 'src/shared/types/global-types';
import { SubAccountType } from 'src/app/store/slices/sub-accounts/types';
import { Balance } from 'src/app/store/slices/balance/types';
import styles from './account.module.scss';

interface IAccountProps {
    selectedSubAccount: Nullable<SubAccountType>
    balance: Nullable<Balance>
    balanceAllowed: string
    closeMenu: () => void
    resetPnl: (subAccountId: Nullable<number>, tradingType: TradingType) => void
    accountTitle: string
    accountBalance: string
    accountPnlUsdt: string
    accountPnlPercent: string
    balanceType: number

}

const Account = ({
  selectedSubAccount, balance, closeMenu, resetPnl, balanceAllowed, balanceType, accountTitle, accountBalance, accountPnlUsdt, accountPnlPercent,
} : IAccountProps) => {
  const { t } = useTranslation();
  const balanceVisible = useSelector(selectHiddenBalance);
  const accountUrl = selectedSubAccount ? `/sub-accounts/${selectedSubAccount.id}` : '/';

  const type: TradingType = balanceType === ETradingType.futures ? 'FUTURES' : 'SPOT';

  return (
    <div className={styles.account}>
      <div className={styles.accountBlockTitle}>
        <span className={styles.blockTitle}>{accountTitle}</span>
        <span className={styles.blockLink}>
          <Tooltip onClick={closeMenu} withArrow label={t('account review')} position="bottom" offset={3}>
            <Link to={`${accountUrl}?balanceType=${balanceType}`}>
              <ArrowIconSvg />
            </Link>
          </Tooltip>
        </span>
      </div>
      <div className={styles.value}>
        <div className={styles.balance}>
          <span className={className(styles.valueMoney, 'mask')}>{!balanceVisible ? `≈ ${accountBalance}` : HIDDEN_BALANCES}</span>
        </div>
        <Tooltip withArrow label={t('available balance')} position="bottom" offset={3}>
          <div className={className(styles.availableBalance, 'mask')}>
            | {!balanceVisible ? (balance ? toFixedDecimalPrecision(balanceAllowed, USDT_PRECISION).replace(/^0$/, '0.0') : '0.0') : HIDDEN_BALANCES} USDT
          </div>
        </Tooltip>
      </div>
      <p className={className(
        styles.increase,
        { [styles.minus]: parseFloat(balance?.spot_pnl_usdt || '0.0') < 0 },
        { [styles.plus]: parseFloat(balance?.spot_pnl_usdt || '0.0') >= 0 },
      )}
      >
        <span className={className(
          styles.pnl,
          'mask',
          { [styles.minus]: parseFloat(accountPnlUsdt) < 0 },
          { [styles.plus]: parseFloat(accountPnlUsdt) >= 0 },
        )}
        >{!balanceVisible ? `≈ ${accountPnlUsdt}` : HIDDEN_BALANCES}
        </span>
        <span className={className(
          styles.pnlAsset,
          { [styles.minus]: parseFloat(accountPnlUsdt) < 0 },
          { [styles.plus]: parseFloat(accountPnlUsdt) >= 0 },

        )}
        >USDT
        </span>
        <span className={className(
          styles.divider,
          { [styles.minus]: parseFloat(accountPnlUsdt) < 0 },
          { [styles.plus]: parseFloat(accountPnlUsdt) >= 0 },
        )}
        >|
        </span>
        <span className={className(
          styles.pnlPercent,
          { [styles.minus]: parseFloat(accountPnlPercent) < 0 },
          { [styles.plus]: parseFloat(accountPnlPercent) >= 0 },

        )}
        >{ accountPnlPercent} %
        </span>

        <Tooltip withArrow label={t('reset pnl')} position="bottom" offset={3}>
          <ReloadIconSvg onClick={() => resetPnl(balance?.sub_account_id, type)} className={styles.resetPnl} />
        </Tooltip>
      </p>
    </div>
  );
};

export default Account;
