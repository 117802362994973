import className from 'classnames';
import { Loader } from '@mantine/core';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useAppDispatch } from 'src/app/store/store';
import { fetchVerifyKycUrl } from 'src/app/store/slices/kyc/thunks';
import { selectKyc } from 'src/app/store/slices/kyc/selectors';
import { selectUser } from 'src/app/store/slices/user/selectors';
import { EKycStatus } from 'src/app/store/slices/user/types';
import { ReactComponent as HamsterPassportIconSvg } from 'src/shared/assets/images/hamster-passport.svg';
import { EStatus } from 'src/shared/types/global-types';
import useAlert from 'src/shared/libs/hooks/use-alert';

import styles from './passport.module.scss';

interface IPassportProps {
  visible: boolean
  horizontal?: boolean
}

const Passport = ({ visible, horizontal }: IPassportProps) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const { status } = useSelector(selectKyc);
  const { kycStatus } = useSelector(selectUser);
  const { setAlertMessage } = useAlert();

  const statusClassName = kycStatus === EKycStatus.IN_PROCESS ? styles.process
    : kycStatus === EKycStatus.VERIFIED ? styles.verified
      : kycStatus === EKycStatus.NOT_VERIFIED ? styles.noVerified
        : kycStatus === EKycStatus.CREATED ? styles.created
          : kycStatus === EKycStatus.IN_REVIEW ? styles.process
            : '';

  const verifyKyc = async () => {
    try {
      const { payload } = await dispatch(fetchVerifyKycUrl());
      if (typeof payload === 'string' && payload.startsWith('http')) {
        window.open(payload, '_blank');
      } else if (typeof payload === 'string' && payload === 'Network Error') {
        setAlertMessage(t('connection error'), 'error');
      } else if (payload && typeof payload === 'object' && 'detail' in payload) {
        setAlertMessage(t('account verified'), 'success');
      } else {
        setAlertMessage(t('invalid url'), 'error');
      }
    } catch (error) {
      console.debug('KYC check error:', error);
      setAlertMessage('internal error', 'error');
    }
  };

  return !visible ? (
    <div className={className(styles.passport, { [styles.horizontal]: horizontal })}>
      <HamsterPassportIconSvg />
      <div className={styles.passportBody}>
        <div className={styles.title}>
          {kycStatus === EKycStatus.CREATED && (<span>{t('verification not completed')}</span>)}
          {kycStatus === EKycStatus.VERIFIED && (<span>{t('verification completed')}</span>)}
          {kycStatus === EKycStatus.NOT_VERIFIED && (<span>{t('verification rejected')}</span>)}
          {kycStatus === EKycStatus.IN_PROCESS && (<span>{t('verification in process')}</span>)}
          {kycStatus === EKycStatus.IN_REVIEW && (<span>{t('verification under review')}</span>)}

        </div>
        <p className={styles.info}>{t('basic verification required') }</p>

        <div className={className(styles.verificationStatus, statusClassName)}>
          {kycStatus === EKycStatus.VERIFIED && (<span className="status-text">{t('verification passed')}</span>)}
          {kycStatus === EKycStatus.CREATED && (
            <button onClick={verifyKyc}>
              {status === EStatus.loading ? <Loader size={16} color="white" /> : t('pass verification')}
            </button>
          )}
          {kycStatus === EKycStatus.IN_PROCESS && (
            <button onClick={verifyKyc}> {status === EStatus.loading ? <Loader size={16} color="white" /> : `${t('in progress')}...`}.</button>
          )}
          {kycStatus === EKycStatus.IN_REVIEW && (<span className="status-text">{t('under review')}...</span>)}
          {kycStatus === EKycStatus.NOT_VERIFIED && (
            <button onClick={verifyKyc}>
              {status === EStatus.loading ? <Loader size={16} color="white" /> : t('retry_again')}
            </button>
          )}
          {!EKycStatus[kycStatus] && (
            <span className="status-text">{t('undefined status')}</span>
          )}
        </div>
      </div>
    </div>
  ) : null;
};

export default Passport;
