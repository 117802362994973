import {
  useEffect, useState, useRef, useLayoutEffect,
  memo,
  useMemo,
} from 'react';
import { useTranslation } from 'react-i18next';
import { LoadingOverlay, Tooltip } from '@mantine/core';
import { useSelector } from 'react-redux';
import className from 'classnames';
import {
  DragDropContext, Droppable, Draggable, DropResult,
} from 'react-beautiful-dnd';

import { ReactComponent as ThreePointsIconSvg } from 'src/shared/assets/images/trading/three-points.svg';
import { ReactComponent as SortIconSvg } from 'src/shared/assets/images/sort.svg';
import { ReactComponent as CloseIconSvg } from 'src/shared/assets/images/trading/close.svg';
import { ReactComponent as GridIconSvg } from 'src/shared/assets/images/trading/grid.svg';
import { ReactComponent as ListCionSvg } from 'src/shared/assets/images/trading/list.svg';
import { ReactComponent as CloseXIconSvg } from 'src/shared/assets/images/trading/closeX.svg';

import {
  getFromLocalStorage, multiplyValues, saveToLocalStorage, toFixedDecimal,
  toFixedDecimalPrecision,
} from 'src/shared/libs/helpers/helper.lib';

import Button from 'src/shared/ui/button/button/button';
import useAlert from 'src/shared/libs/hooks/use-alert';

import { useAppDispatch } from 'src/app/store/store';
import { BalanceType } from 'src/entities/balance-type';
import { ETradingType, EStatus } from 'src/shared/types/global-types';
import { subscribeInstrument, unsubscribeInstrument } from 'src/app/store/slices/sub-accounts/thunks';
import { useTheme } from 'src/shared/libs/hooks/use-theme';
import { selectHiddenBalance } from 'src/pages/settings/model/selectors';
import { HIDDEN_BALANCES } from 'src/shared/constants/constants';
import useMobile from 'src/shared/libs/hooks/use-mobile';
import {
  ColumnOrderItem, Order, OrderToClosed, SortFieldOrder,
  PositionCloseDetail,
} from '../../model/types';
import {
  selectOrders, selectStatusCloseOrders, selectStatusOrderCancel, selectStatusOrders,
} from '../../model/selectors';
import { fetchOrderCancel, fetchOrderCancelAll } from '../../model/thunks';
import { orderTableColumns, ordersColumn } from '../../constants';

import Empty from '../../../../entities/empty/empty';
import useSubAccount from '../../../../shared/libs/hooks/use-sub-account-name';
import styles from './orders.module.scss';

const Orders = memo(() => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const isMobile = useMobile();

  const { theme } = useTheme();
  const { setAlertMessage } = useAlert();
  const { getSubAccountNameById } = useSubAccount();

  const orders = useSelector(selectOrders);
  const statusOrders = useSelector(selectStatusOrders);
  const balanceVisible = useSelector(selectHiddenBalance);
  const statusCloseOrders = useSelector(selectStatusOrderCancel);
  const statusCloseAllOrders = useSelector(selectStatusCloseOrders);

  const [orderCardType, setOrderCardType] = useState<'card' | 'list'>(getFromLocalStorage('orderCardType', 'card'));
  const [tableColumns, setTableColumns] = useState(orderTableColumns);
  const [filterAccountType, setFilterAccountType] = useState<string | number>('');
  const [showOptionsOrders, setShowOptionsOrders] = useState(false);
  const [sortField, setSortField] = useState<SortFieldOrder>(null);
  const [sortOrder, setSortOrder] = useState<'asc' | 'desc'>('asc');
  const [currentSortColumn, setCurrentSortColumn] = useState('');
  const [columnOrder, setColumnOrder] = useState<ColumnOrderItem[]>(ordersColumn);
  const [prevOrdersLength, setPrevOrdersLength] = useState(0);
  const [selectedOrder, setSelectedOrder] = useState<Order[]>([]);
  const [closeOrderButtonById, setCloseOrderButtonById] = useState<string[]>([]);

  const optionsRef = useRef<HTMLDivElement>(null);
  const optionsButtonRef = useRef<HTMLButtonElement>(null);

  const ordersSortedAndFiltered = useMemo(() => {
    if (!Array.isArray(orders)) return [];

    // Сортировка данных
    const sortedData = orders.slice().sort((a, b) => {
      if (sortField === 'price') {
        const priceA = parseFloat(a.price);
        const priceB = parseFloat(b.price);

        return sortOrder === 'asc' ? priceA - priceB : priceB - priceA;
      }
      if (sortField === 'quantity') {
        const quantityA = parseFloat(a.quantity);
        const quantityB = parseFloat(b.quantity);

        return sortOrder === 'asc' ? quantityA - quantityB : quantityB - quantityA;
      }
      if (sortField === 'quantity_dollars') {
        const quantityA = parseFloat(multiplyValues(a.price, a.quantity));
        const quantityB = parseFloat(multiplyValues(b.price, b.quantity));

        return sortOrder === 'asc' ? quantityA - quantityB : quantityB - quantityA;
      }
      if (sortField === 'instrument_symbol') {
        return sortOrder === 'asc'
          ? a.instrument.symbol.localeCompare(b.instrument.symbol)
          : b.instrument.symbol.localeCompare(a.instrument.symbol);
      }
      if (sortField === 'exchange_name') {
        return sortOrder === 'asc'
          ? a.exchange.name.localeCompare(b.exchange.name)
          : b.exchange.name.localeCompare(a.exchange.name);
      }
      if (sortField === 'last_price') {
        const lastPriceA = parseFloat(a.instrument.price.ask);
        const lastPriceB = parseFloat(b.instrument.price.ask);

        return sortOrder === 'asc' ? lastPriceA - lastPriceB : lastPriceB - lastPriceA;
      }
      if (sortField === 'type') {
        return sortOrder === 'asc'
          ? a.type.localeCompare(b.type)
          : b.type.localeCompare(a.type);
      }

      if (sortField) {
        return sortOrder === 'asc'
          ? (a[sortField] < b[sortField] ? -1 : 1)
          : (a[sortField] > b[sortField] ? -1 : 1);
      }

      return 0;
    });

    // Фильтрация данных
    return sortedData.filter((data) => {
      if (filterAccountType === '') {
        return true;
      }
      return data.instrument.instrument_type === filterAccountType;
    });
  }, [orders, sortField, sortOrder, filterAccountType]);

  const handleSetOrderCardType = (type: 'list' | 'card') => {
    saveToLocalStorage('orderCardType', type);
    setOrderCardType(type);
  };

  const handleSort = (field: any) => {
    setCurrentSortColumn(field);

    if (field === sortField) {
      setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
    } else {
      setSortField(field);
      setSortOrder('asc');
    }
  };

  const handleOption = (key: string) => {
    if (key === 'checkbox') return;

    setTableColumns((prevTableItems) => {
      const updatedTableColumns = {
        ...prevTableItems,
        [key as keyof typeof tableColumns]: {
          ...prevTableItems[key as keyof typeof tableColumns],
          show: !prevTableItems[key as keyof typeof tableColumns].show,
        },
      };

      saveToLocalStorage('ordersTableColumns', updatedTableColumns);
      return updatedTableColumns;
    });
  };

  const handleSelectOrder = (order: Order) => {
    if (selectedOrder.find((item) => item.id === order.id)) {
      setSelectedOrder((prev) => {
        prev = prev.filter((item) => item.id !== order.id);
        return prev;
      });
    } else {
      setSelectedOrder((prev) => {
        prev = [...prev, order];
        return prev;
      });
    }
  };

  const handleSelectAll = () => {
    setSelectedOrder(selectedOrder.length < 1 ? [...ordersSortedAndFiltered] : []);
  };

  const closeOrderSelected = async () => {
    const formattedOrderToClose: OrderToClosed[] = selectedOrder.map((order) => ({
      order_id: order.id,
      sub_account_id: order.sub_account_id,
      trading_type: order.instrument.instrument_type,
      exchange_id: order.exchange.id,
    }));

    if (!formattedOrderToClose.length) return;

    const { payload } = await dispatch(fetchOrderCancel(formattedOrderToClose));

    if (Array.isArray(payload) && payload.length === 0) {
      setAlertMessage('orders_canceled', 'success');
      setSelectedOrder([]);
    } else if (Array.isArray(payload) && payload.length > 0) {
      payload.forEach((detail: PositionCloseDetail) => {
        const message = `asset: ${detail.symbol}, detail: ${detail.detail}`;
        setAlertMessage(message, 'error');
      });
    } else if (typeof payload === 'string') {
      setAlertMessage(payload, 'error');
    } else {
      setAlertMessage('internal error', 'error');
    }
  };

  const closeOrder = async (order: Order) => {
    const formattedOrderToClose: OrderToClosed = {
      order_id: order.id,
      sub_account_id: order.sub_account_id,
      trading_type: order.instrument.instrument_type,
      exchange_id: order.exchange.id,
    };

    setCloseOrderButtonById((prev) => prev.concat(order.id));

    const { payload } = await dispatch(fetchOrderCancel([formattedOrderToClose]));

    if (Array.isArray(payload) && payload.length === 0) {
      setAlertMessage('order_canceled', 'success');
      setCloseOrderButtonById((prev) => prev.filter((item) => item !== order.id));
      setSelectedOrder((prev) => prev.filter((item) => item.id !== order.id));
    } else if (Array.isArray(payload) && payload.length > 0) {
      payload.forEach((detail: PositionCloseDetail) => {
        const message = `asset: ${detail.symbol}, detail: ${detail.detail}`;
        setAlertMessage(message, 'error');
      });
      setCloseOrderButtonById((prev) => prev.filter((item) => item !== order.id));
    } else if (typeof payload === 'string') {
      setAlertMessage(payload, 'error');
      setCloseOrderButtonById((prev) => prev.filter((item) => item !== order.id));
    } else {
      setAlertMessage('internal error', 'error');
      setCloseOrderButtonById((prev) => prev.filter((item) => item !== order.id));
    }
  };

  const closeOrderAll = async () => {
    if (!orders.length) return;

    const { payload } = await dispatch(fetchOrderCancelAll());

    if (Array.isArray(payload) && payload.length === 0) {
      setAlertMessage('orders_canceled', 'success');
      setSelectedOrder([]);
    } else if (Array.isArray(payload) && payload.length > 0) {
      payload.forEach((detail: PositionCloseDetail) => {
        const message = `asset: ${detail.symbol}, detail: ${detail.detail}`;
        setAlertMessage(message, 'error');
      });
    } else if (typeof payload === 'string') {
      setAlertMessage(payload, 'error');
    } else {
      setAlertMessage('internal error', 'error');
    }
  };

  const onDragEnd = (result: DropResult) => {
    if (!result.destination) return;

    const newOrder = [...columnOrder];
    const [movedColumn] = newOrder.splice(result.source.index, 1);
    newOrder.splice(result.destination.index, 0, movedColumn);

    setColumnOrder(newOrder);

    saveToLocalStorage('ordersColumnOrder', newOrder);
  };

  useLayoutEffect(() => {
    const ordersColumnLocalStorage = getFromLocalStorage('ordersColumnOrder', null);
    if (ordersColumnLocalStorage) {
      setColumnOrder(ordersColumnLocalStorage);
    }
  }, []);

  useLayoutEffect(() => {
    const ordersTableColumnsLocalStorage = getFromLocalStorage('ordersTableColumns', null);

    if (ordersTableColumnsLocalStorage) {
      setTableColumns(ordersTableColumnsLocalStorage);
    }
  }, []);

  useLayoutEffect(() => {
    if (isMobile) {
      setOrderCardType('card');
    }
  }, [isMobile]);

  useEffect(() => {
    if (orders && orders.length > 0) {
      const instrumentIds: number[] = orders.map((order) => order.instrument.id);
      const uniqueIds = [...new Set(instrumentIds)];

      if (instrumentIds.length > 0 && orders.length !== prevOrdersLength) {
        subscribeInstrument(uniqueIds);

        // Update the previous length of assets
        setPrevOrdersLength(orders.length);
      }
    }
  }, [orders]);

  useEffect(() => {
    const showOptionsHandler = (e: MouseEvent) => {
      e.stopPropagation();

      if (!optionsRef.current?.contains(e.target as Node) && !optionsButtonRef.current?.contains(e.target as Node)) {
        setShowOptionsOrders(false);
      }
    };

    window.addEventListener('click', showOptionsHandler);

    return () => {
      window.removeEventListener('click', showOptionsHandler);
    };
  }, [showOptionsOrders]);

  useEffect(() => () => unsubscribeInstrument(), []);

  return (
    <section className={styles.orders}>
      <div className={styles.title}>
        <h2>{t('orders')}</h2>
        <div className={styles.actionsOptions}>
          <button ref={optionsButtonRef} onClick={() => setShowOptionsOrders((prev) => !prev)}>
            <ThreePointsIconSvg />
          </button>
        </div>

        <div ref={optionsRef} className={className(styles.options, { [styles.visible]: showOptionsOrders })}>
          <p className={styles.optionsTitle}>{t('table-settings')}</p>
          <ul>
            {Object.entries(tableColumns).map(([key, value]) => (
              <li
                role="button"
                tabIndex={0}
                onKeyDown={() => handleOption(key)}
                onClick={() => handleOption(key)}
                key={key}
              >
                <input
                  className={styles.checkbox}
                  type="checkbox"
                  checked={value.show}
                  onChange={() => {}}
                />
                <span>{t(value.name)}</span>
              </li>
            ))}
          </ul>
        </div>
      </div>

      <div className={styles.content}>
        <div className={styles.header}>
          <div className={styles.filters}>
            <button className={className({ [styles.activeFilter]: filterAccountType === '' })} onClick={() => setFilterAccountType('')}>{t('all')}</button>
            <button className={className({ [styles.activeFilter]: filterAccountType === ETradingType.futures })} onClick={() => setFilterAccountType(ETradingType.futures)}>{t('futures')} <span>F</span> </button>
            <button className={className({ [styles.activeFilter]: filterAccountType === ETradingType.spot })} onClick={() => setFilterAccountType(ETradingType.spot)}>{t('spot')} <span>S</span> </button>
          </div>
          <div className={styles.actions}>

            <div className={styles.windows}>
              <Tooltip withArrow label={t('table')} position="bottom" offset={5}>
                <button className={className({ [styles.active]: orderCardType === 'list' })} onClick={() => handleSetOrderCardType('list')}><ListCionSvg /></button>
              </Tooltip>

              <Tooltip withArrow label={t('cards')} position="bottom" offset={5}>
                <button className={className({ [styles.active]: orderCardType === 'card' })} onClick={() => handleSetOrderCardType('card')}><GridIconSvg /></button>
              </Tooltip>
            </div>

            <div className={styles.tableActions}>
              {selectedOrder.length > 0 && <Button minWidth="175px" loading={statusCloseOrders === EStatus.loading} disabled={statusCloseOrders === EStatus.loading} onClick={closeOrderSelected} background="orange">{t('cancel_selected')} ({selectedOrder.length})</Button>}
              {orders && orders.length > 0 && <Button minWidth="140px" loading={statusCloseAllOrders === EStatus.loading} disabled={statusCloseAllOrders === EStatus.loading} onClick={closeOrderAll} background="green">{t('cancel_all')}</Button>}
            </div>

          </div>
        </div>

        {orderCardType === 'list' && (
          <div className={styles.bodyList}>
            <div className={styles.afterEffcet} />
            <DragDropContext onDragEnd={onDragEnd}>
              <table className={styles.tableOrders}>

                <thead>
                  <Droppable droppableId="columns" direction="horizontal">
                    {(provided, snapshot) => (
                      <tr
                        ref={provided.innerRef}
                        {...provided.droppableProps}
                        style={{
                          background: snapshot.isDraggingOver ? (theme === 'light' ? '#131313' : '#474747') : (theme === 'light' ? '#131313' : '#474747'),
                        }}
                      >
                        {columnOrder.map((column, index) => (
                          <Draggable key={column.key} draggableId={column.key} index={index}>
                            {(provided, snapshot) => (
                              <th
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                                style={{
                                  ...provided.draggableProps.style,
                                  background: snapshot.isDragging ? 'transparent' : (theme === 'light' ? '#131313' : '#474747'),
                                  color: snapshot.isDragging ? 'var(--color-main)' : '#BCBCBC',
                                  display: snapshot.isDragging ? 'flex' : '',
                                  width: column.key === 'checkbox' ? '50px' : 'auto',
                                }}
                              >
                                {tableColumns.checkbox.show && column.key === 'checkbox' && (
                                  <input
                                    className={styles.checkbox}
                                    type="checkbox"
                                    checked={selectedOrder.length === ordersSortedAndFiltered.length && ordersSortedAndFiltered.length > 0}
                                    onChange={handleSelectAll}
                                  />
                                )}
                                {tableColumns.instrument.show && column.key === 'instrument_symbol' && (
                                  <div
                                    role="button"
                                    tabIndex={0}
                                    onKeyDown={() => handleSort(column.key)}
                                    onClick={() => handleSort(column.key)}
                                    className={className(styles.thWrapper, { [styles.activeColumn]: currentSortColumn === column.key })}
                                  >
                                    {t(column.label)}
                                    <SortIconSvg />
                                  </div>
                                )}
                                {tableColumns.exchange.show && column.key === 'exchange_name' && (
                                  <div
                                    role="button"
                                    tabIndex={0}
                                    onKeyDown={() => handleSort(column.key)}
                                    onClick={() => handleSort(column.key)}
                                    className={className(styles.thWrapper, { [styles.activeColumn]: currentSortColumn === column.key })}
                                  >
                                    {t(column.label)}
                                    <SortIconSvg />
                                  </div>
                                )}
                                {tableColumns.subAccountId.show && column.key === 'sub_account_id' && (
                                  <div
                                    role="button"
                                    tabIndex={0}
                                    onKeyDown={() => handleSort(column.key)}
                                    onClick={() => handleSort(column.key)}
                                    className={className(styles.thWrapper, { [styles.activeColumn]: currentSortColumn === column.key })}
                                  >
                                    {t(column.label)}
                                    <SortIconSvg />
                                  </div>
                                )}
                                {tableColumns.quantity.show && column.key === 'quantity' && (
                                  <div
                                    role="button"
                                    tabIndex={0}
                                    onKeyDown={() => handleSort(column.key)}
                                    onClick={() => handleSort(column.key)}
                                    className={className(styles.thWrapper, { [styles.activeColumn]: currentSortColumn === column.key })}
                                  >
                                    {t(column.label)}
                                    <SortIconSvg />
                                  </div>
                                )}
                                {tableColumns.quantity_dollars.show && column.key === 'quantity_dollars' && (
                                  <div
                                    role="button"
                                    tabIndex={0}
                                    onKeyDown={() => handleSort(column.key)}
                                    onClick={() => handleSort(column.key)}
                                    className={className(styles.thWrapper, { [styles.activeColumn]: currentSortColumn === column.key })}
                                  >
                                    {t(column.label)}
                                    <SortIconSvg />
                                  </div>
                                )}
                                {tableColumns.price.show && column.key === 'price' && (
                                  <div
                                    role="button"
                                    tabIndex={0}
                                    onKeyDown={() => handleSort(column.key)}
                                    onClick={() => handleSort(column.key)}
                                    className={className(styles.thWrapper, { [styles.activeColumn]: currentSortColumn === column.key })}
                                  >
                                    {t(column.label)}
                                    <SortIconSvg />
                                  </div>
                                )}
                                {tableColumns.lastPrice.show && column.key === 'last_price' && (
                                  <div
                                    role="button"
                                    tabIndex={0}
                                    onKeyDown={() => handleSort(column.key)}
                                    onClick={() => handleSort(column.key)}
                                    className={className(styles.thWrapper, { [styles.activeColumn]: currentSortColumn === column.key })}
                                  >
                                    {t(column.label)}
                                    <SortIconSvg />
                                  </div>
                                )}
                                {tableColumns.type.show && column.key === 'type' && (
                                  <div
                                    role="button"
                                    tabIndex={0}
                                    onKeyDown={() => handleSort(column.key)}
                                    onClick={() => handleSort(column.key)}
                                    className={className(styles.thWrapper, { [styles.activeColumn]: currentSortColumn === column.key })}
                                  >
                                    {t(column.label)}
                                    <SortIconSvg />
                                  </div>
                                )}
                                {tableColumns.action.show && column.key === 'action' && (
                                  <div
                                    role="button"
                                    tabIndex={0}
                                    onKeyDown={() => handleSort(column.key)}
                                    onClick={() => handleSort(column.key)}
                                    className={className(styles.thWrapper, { [styles.activeColumn]: currentSortColumn === column.key })}
                                  >
                                    {t(column.label)}
                                    <SortIconSvg />
                                  </div>
                                )}
                                {tableColumns.close.show && column.key === 'close' && (
                                  <div className={className(styles.thWrapper, { [styles.activeColumn]: currentSortColumn === column.key })}>
                                    {t(column.label)}
                                  </div>
                                )}
                              </th>
                            )}
                          </Draggable>
                        ))}
                        { provided.placeholder}
                      </tr>
                    )}
                  </Droppable>
                </thead>
                {ordersSortedAndFiltered && ordersSortedAndFiltered.length > 0 && (
                  <tbody>
                    {ordersSortedAndFiltered.map((order) => (
                      <tr key={order.id}>
                        {columnOrder.map((column) => (
                          <td key={column.key}>
                            {tableColumns.checkbox.show && column.key === 'checkbox' && tableColumns.checkbox && (
                              <input
                                className={styles.checkbox}
                                type="checkbox"
                                checked={Boolean(selectedOrder.find((item) => item.id === order.id))}
                                onChange={() => handleSelectOrder(order)}
                              />
                            )}
                            {tableColumns.instrument.show && column.key === 'instrument_symbol' && (
                              <div
                                className={className(styles.instrumentTd, { [styles.spot]: order.instrument.instrument_type === ETradingType.spot })}
                                role="button"
                                tabIndex={0}
                                onClick={() => handleSelectOrder(order)}
                                onKeyDown={() => handleSelectOrder(order)}
                              >
                                {order.instrument.symbol}
                                <BalanceType type={order.instrument.instrument_type} />
                              </div>
                            ) }
                            {tableColumns.exchange.show && column.key === 'exchange_name' && (
                              <div className={styles.exchangeTd}>
                                <img src={order.exchange.image} alt="exchange" />
                              </div>
                            ) }
                            {tableColumns.subAccountId.show && column.key === 'sub_account_id' && (
                              <div className={styles.accountTd}>{getSubAccountNameById(order.sub_account_id)}</div>
                            ) }
                            {tableColumns.quantity.show && column.key === 'quantity' && (
                              <div className="mask">{!balanceVisible ? toFixedDecimal(order.quantity) : HIDDEN_BALANCES}</div>
                            ) }
                            {tableColumns.quantity_dollars.show && column.key === 'quantity_dollars' && (
                              <div className="mask">{!balanceVisible ? multiplyValues(order.price, order.quantity, 2) : HIDDEN_BALANCES}</div>
                            ) }
                            {tableColumns.price.show && column.key === 'price' && (
                              <div className={styles.priceTd}>{toFixedDecimalPrecision(order.price)}</div>
                            ) }
                            {tableColumns.lastPrice.show && column.key === 'last_price' && (
                              <div className={styles.lastPriceTd}>{order.action === 'SELL' ? toFixedDecimalPrecision(order.instrument.price.bid) : toFixedDecimalPrecision(order.instrument.price.ask)}</div>
                            ) }
                            {tableColumns.type.show && column.key === 'type' && (
                              <div className={className(styles.typeTd, {
                                [styles.red]: order.type === 'SL',
                                [styles.green]: order.type === 'TP',
                              })}
                              >{order.type}
                              </div>
                            ) }
                            {tableColumns.action.show && column.key === 'action' && (
                              <div className={className(styles.actionTd, { [styles.sell]: order.action === 'SELL' })}>{order.action}</div>
                            ) }
                            {tableColumns.close.show && column.key === 'close' && (
                              <button disabled={closeOrderButtonById.includes(order.id)} onClick={() => closeOrder(order)} className={styles.canceledTd}>{t('cancel')} <CloseIconSvg /></button>
                            )}
                          </td>
                        ))}
                      </tr>
                    ))}
                  </tbody>
                )}
              </table>
            </DragDropContext>

            {statusOrders === EStatus.loading && (
              <div className={styles.emptyTable}>
                <LoadingOverlay
                  className="loader"
                  style={{ height: 'calc(100% - 100px)' }}
                  visible
                  zIndex={1000}
                  overlayProps={{ radius: 'sm', blur: 2 }}
                  loaderProps={{ color: '#00C37C', type: 'dots' }}
                />
              </div>
            )}
            {statusOrders === EStatus.success && ordersSortedAndFiltered.length === 0 && (
              <div className={styles.emptyTable}>
                <Empty className={styles.emptyText}>{t('your_orders_will_appear_here')}</Empty>
              </div>
            )}
            {statusOrders === EStatus.rejected && (
              <div className={styles.emptyTable}>
                <Empty className={styles.emptyTextError} horizontal error>
                  {t('internal error fetch')}
                </Empty>
              </div>
            )}
          </div>
        )}

        {orderCardType === 'card' && (
          <div className={styles.bodyCard}>

            {ordersSortedAndFiltered && ordersSortedAndFiltered.length > 0 && (
              <div className={styles.positionCardContainer}>
                {ordersSortedAndFiltered.map((order) => (
                  <div className={className(styles.positionCardWrapper, { [styles.active]: Boolean(selectedOrder.find((item) => item.id === order.id)) })} key={order.id}>
                    <div
                      className={styles.positionCard}
                      role="button"
                      tabIndex={0}
                      onClick={() => handleSelectOrder(order)}
                      onKeyDown={() => handleSelectOrder(order)}
                    >
                      {(tableColumns.exchange.show || tableColumns.instrument.show || tableColumns.action.show) ? (
                        <div className={styles.box}>
                          {tableColumns.exchange.show && (
                            <div className={styles.exchange}> <img src={order.exchange.image} alt="биржа" /></div>
                          ) }
                          {tableColumns.instrument.show && (
                            <div className={styles.instrument}>
                              {order.instrument.symbol}
                              <BalanceType type={order.instrument.instrument_type} />
                            </div>
                          ) }
                          {tableColumns.action.show && (
                            <div className={className(styles.actionTd, { [styles.sell]: order.action === 'SELL' })}>{order.action}</div>
                          )}
                        </div>
                      ) : null}
                      {tableColumns.subAccountId.show ? (
                        <div className={className(styles.box, styles.margin)}>
                          <div className={styles.accountTd}>{t('account')}: {getSubAccountNameById(order.sub_account_id)}</div>
                        </div>
                      ) : null }
                      {tableColumns.type.show ? (
                        <div className={styles.box}>
                          <div className={className(styles.typeTd, {
                            [styles.red]: order.type === 'SL',
                            [styles.green]: order.type === 'TP',
                          })}
                          >{t('order_type')}: {order.type}
                          </div>
                        </div>
                      ) : null }
                      {tableColumns.price.show || tableColumns.lastPrice.show ? (
                        <div className={styles.box}>
                          {t('price')}:
                          {tableColumns.price.show && <div className={styles.priceTd}>{toFixedDecimalPrecision(order.price)}</div>}
                          { tableColumns.price.show && tableColumns.lastPrice.show && '|'}
                          {tableColumns.lastPrice.show && <div className={styles.lastPriceTd}>{order.action === 'SELL' ? toFixedDecimalPrecision(order.instrument.price.bid) : toFixedDecimalPrecision(order.instrument.price.ask)}</div>}
                        </div>
                      ) : null }
                      {tableColumns.quantity.show || tableColumns.quantity_dollars.show ? (
                        <div className={className(styles.box, styles.margin)}>
                          {t('quantity')}:
                          { tableColumns.quantity.show && <div className="mask">{!balanceVisible ? toFixedDecimal(order.quantity) : HIDDEN_BALANCES}</div>}
                          { tableColumns.quantity.show && tableColumns.quantity_dollars.show && '|'}
                          { tableColumns.quantity_dollars.show && <div className="mask">{!balanceVisible ? `${multiplyValues(order.price, order.quantity, 2)}$` : HIDDEN_BALANCES}</div>}
                        </div>
                      ) : null }
                      {tableColumns.close.show ? (
                        <div className={styles.box}>
                          <Button
                            loading={closeOrderButtonById.includes(order.id)}
                            disabled={closeOrderButtonById.includes(order.id)}
                            background="black"
                            onClick={(e: any) => {
                              e.stopPropagation();
                              closeOrder(order);
                            }}
                          >
                            {t('cancel')}
                            <CloseXIconSvg />
                          </Button>
                        </div>
                      ) : null }
                    </div>
                  </div>
                ))}
              </div>
            )}

            {statusOrders === EStatus.loading && (
              <div className={styles.emptyTable}>
                <LoadingOverlay
                  className="loader"
                  style={{ height: 'calc(100% - 100px)' }}
                  visible
                  zIndex={1000}
                  overlayProps={{ radius: 'sm', blur: 2 }}
                  loaderProps={{ color: '#00C37C', type: 'dots' }}
                />
              </div>
            )}
            {statusOrders === EStatus.success && ordersSortedAndFiltered.length === 0 && (
              <div className={styles.emptyTable}>
                <Empty className={styles.emptyText}>{t('your_orders_will_appear_here')}</Empty>
              </div>
            )}
            {statusOrders === EStatus.rejected && (
              <div className={styles.emptyTable}>
                <Empty className={styles.emptyTextError} horizontal error>
                  {t('internal error fetch')}
                </Empty>
              </div>
            )}
          </div>
        )}
      </div>
    </section>
  );
});

export default Orders;
