import { Divider } from '@mantine/core';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { unwrapResult } from '@reduxjs/toolkit';
import { useTranslation } from 'react-i18next';
import {
  memo, useMemo, useState,
} from 'react';
import { useTheme } from 'src/shared/libs/hooks/use-theme';
import { EThemeMode } from 'src/app/store/slices/user/types';
import { BalanceType } from 'src/entities/balance-type';
import { useAppDispatch } from 'src/app/store/store';
import { selectHiddenBalance } from 'src/pages/settings/model/selectors';
import { setCollateralStatus } from 'src/app/store/slices/sub-accounts/slice';
import { ETradingType, Nullable } from 'src/shared/types/global-types';
import { selectSubAccountsAssets } from 'src/app/store/slices/sub-accounts/selectors';
import { SubAccountAsset, SubAccountType } from 'src/app/store/slices/sub-accounts/types';
import { HIDDEN_BALANCES, USDT_PRECISION } from 'src/shared/constants/constants';
import { CollateralStatus, fetchSetCollateralStatus } from 'src/app/store/slices/sub-accounts/thunks';
import {
  decimalizeQuantity, decimalizeQuantityLocked, multiplyValues, scrollToTop, toFixed,
} from 'src/shared/libs/helpers/helper.lib';
import { ReactComponent as ReplenishmentIconSvg } from 'src/shared/assets/images/account/replenishment.svg';
import { ReactComponent as WithdrawalIconSvg } from 'src/shared/assets/images/account/withdrawal.svg';

import Button from 'src/shared/ui/button/button/button';
import Toogler from 'src/shared/ui/toogler/toogler';
import useAlert from 'src/shared/libs/hooks/use-alert';
import useExchange from 'src/shared/libs/hooks/use-exchange';
import useSubAccount from 'src/shared/libs/hooks/use-sub-account-name';

import classNames from 'classnames';
import styles from './asset-table-bybit-item-adaptive.module.scss';

interface IAssetTableBybitItemAdaptiveProps {
    asset: SubAccountAsset
    subAccount: Nullable<SubAccountType>
}

const AssetTableBybitItemAdaptive = memo(({
  asset, subAccount,
}: IAssetTableBybitItemAdaptiveProps) => {
  const subAccountId = Number(window.location.pathname.slice('/sub-accounts/'.length));

  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const { theme } = useTheme();
  const { getExchangeImageById } = useExchange();
  const { getSubAccountNameById } = useSubAccount();
  const { setAlertMessage } = useAlert();

  const [collateralcurrentAsset, setCollateralcurrentAsset] = useState<Nullable<number>>(null);

  const balanceVisible = useSelector(selectHiddenBalance);
  const subAccountAssets = useSelector(selectSubAccountsAssets);

  const subAccountAsset = useMemo(() => subAccountAssets?.find((subAccountAsset) => subAccountAsset.asset_id === asset.asset_id && subAccountAsset.sub_account_id === asset.sub_account_id), [subAccountAssets, asset]);

  const renderPnl = (pnl: string) => (
    <>
      {Number(pnl) === 0 && (
        <span className={styles.zero}>{pnl} %</span>
      )}
      {Number(pnl) > 0 && (
        <span className={styles.plus}>+{Number(toFixed(pnl, 2)).toLocaleString()} %</span>
      )}
      {Number(pnl) < 0 && (
        <span className={styles.minus}>-{Number(toFixed(pnl, 2)).toLocaleString()} %</span>
      )}

    </>
  );

  const handleWithdrawalAsset = () => {
    const route = '/transfers';
    const subAccountId = `?sub_account_id=${asset.sub_account_id}`;
    const assetSymbol = `&asset_symbol=${asset.asset.symbol}`;
    const assetId = `&asset_id=${asset.asset.id}`;
    const tabIndex = '&tab_index=1';

    const navigateWithdrawal = route + subAccountId + assetSymbol + assetId + tabIndex;

    navigate(navigateWithdrawal, { replace: true });
  };

  const handleReplenishmentAsset = () => {
    const route = '/transfers';
    const exchangeId = `?exchange_id=${subAccount?.exchange_id}`;
    const subAccountId = `&replenishment_sub_account_id=${asset.sub_account_id}`;
    const assetId = `&asset_id=${asset.asset.id}`;
    const assetType = `&asset_type=${asset.asset_type === ETradingType.spot ? 'SPOT' : 'FUTURES'}`;
    const tabIndex = '&tab_index=0';
    const navigateReplenishment = route + exchangeId + subAccountId + assetId + assetType + tabIndex;

    navigate(navigateReplenishment, { replace: true });
    scrollToTop();
  };

  const handleCollateralSwitch = async (asset: SubAccountAsset) => {
    const params: CollateralStatus = {
      asset_id: asset.asset_id,
      sub_account_id: subAccountId,
      collateralizable: !subAccountAsset?.collateralizable,
    };

    try {
      setCollateralcurrentAsset(asset.asset_id);
      const response = await dispatch(fetchSetCollateralStatus(params));
      const payload = unwrapResult(response);

      if (payload) {
        dispatch(setCollateralStatus(params));
      } else {
        setAlertMessage('negative_response', 'error');
      }
    } catch (error) {
      console.debug('Error fetchSetCollateralStatus', error);
    } finally {
      setCollateralcurrentAsset(null);
    }
  };

  return (
    <div className={styles.assetContainer} key={asset.id}>
      <div className={styles.header}>
        <h2>{asset.asset.symbol}
          <BalanceType type={subAccountAsset?.collateralizable ? ETradingType.futures : ETradingType.spot} />
        </h2>
        <div className={styles.subAccount}>
          <span className={styles.account}>{t('account')}:</span>
          <img src={getExchangeImageById(subAccount?.exchange_id)} alt="exchange" />
          <span>{getSubAccountNameById(asset.sub_account_id)}</span>
        </div>
      </div>

      <Divider className={styles.divider} color={theme === EThemeMode.LIGHT ? '#E8E8E8' : '#3A3A3A'} />

      <div className={styles.box}>
        <span>{t('amount')}</span>
        <span className="mask">{!balanceVisible ? decimalizeQuantity(asset.quantity, asset.asset.symbol) : HIDDEN_BALANCES}</span>
      </div>

      <div className={styles.box}>
        <span>{t('available')}</span>
        <span className="mask">{!balanceVisible ? decimalizeQuantityLocked(asset) : HIDDEN_BALANCES}</span>
      </div>

      {/* <div className={styles.box}>
        <span>{t('pnl_day_percent')}</span>
        <span>{renderPnl('0')}</span>
      </div> */}

      <div className={styles.box}>
        <span>USDT</span>
        <span className={classNames(styles.available, 'mask')}>
          {asset.instrument
            ? (!balanceVisible ? `≈ ${multiplyValues(asset.instrument.price.bid, asset.quantity, USDT_PRECISION)} USDT` : HIDDEN_BALANCES)
            : '-'}
        </span>
      </div>

      <div className={styles.box}>
        <span>{t('сollateral')}</span>
        <Toogler
          isBlocked={!asset.collateralizable && !asset.asset.collateral_switch}
          isActiveDisabled={!asset.asset.collateral_switch && asset.collateralizable}
          onChange={() => handleCollateralSwitch(asset)}
          isActive={Boolean(subAccountAsset?.collateralizable)}
          isLoading={collateralcurrentAsset === asset.asset_id}
        />
      </div>

      { asset.asset_type === ETradingType.spot && (
        <div className={styles.box}>
          <Button onClick={handleWithdrawalAsset} background="black"><WithdrawalIconSvg className={styles.withdrawalIcon} /> {t('withdraw_funds')}</Button>
          <Button onClick={handleReplenishmentAsset} background="black"><ReplenishmentIconSvg className={styles.replenishmentIcon} /> {t('deposit_coin')}</Button>
        </div>
      )}
    </div>
  );
});

export default AssetTableBybitItemAdaptive;
