import { useEffect } from 'react';
import * as Sentry from '@sentry/react';
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from 'react-router-dom';

const filterUnhandledRejection = (event: Sentry.ErrorEvent) => {
  if (event.exception && event.exception.values?.[0]?.type === 'UnhandledRejection') {
    return null; // skip event
  }
  return event; // approved event
};

Sentry.init({
  dsn: window.appConfig.REACT_APP_SENTRY_DSN,
  beforeSend(event: Sentry.ErrorEvent) {
    return filterUnhandledRejection(event);
  },
  integrations: [
    // Sentry.feedbackIntegration({
    //   // Additional SDK configuration goes in here, for example:
    //   colorScheme: 'system',
    //   isNameRequired: true,
    //   isEmailRequired: true,
    // }),
    // See docs for support of different versions of variation of react router
    // https://docs.sentry.io/platforms/javascript/guides/react/configuration/integrations/react-router/
    Sentry.reactRouterV6BrowserTracingIntegration({
      useEffect,
      useLocation,
      useNavigationType,
      createRoutesFromChildren,
      matchRoutes,
    }),
    Sentry.replayIntegration(
      {
        maskAllText: false,
        blockAllMedia: false,
        maxReplayDuration: 300000,
        mask: ['.mask'],
      },
    ),
    Sentry.browserTracingIntegration(),
  ],
  environment: window.appConfig.REACT_APP_ENV,
  release: window.appConfig.REACT_APP_ENV,

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for tracing.
  // Learn more at
  // https://docs.sentry.io/platforms/javascript/configuration/options/#traces-sample-rate
  tracesSampleRate: 1.0,

  // Set `tracePropagationTargets` to control for which URLs trace propagation should be enabled
  tracePropagationTargets: [
    'localhost',
    /^https:\/\/dev\.league\.broker/,
    /^https:\/\/app\.league\.broker/,
    /^https:\/\/test\.league\.broker/,
  ],
  // Capture Replay for 10% of all sessions,
  // plus for 100% of sessions with an error
  // Learn more at
  // https://docs.sentry.io/platforms/javascript/session-replay/configuration/#general-integration-configuration
  replaysSessionSampleRate: 0.2,
  replaysOnErrorSampleRate: 1.0,
});

// Sentry.captureMessage('Sentry configured correctly', 'info');
